import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ReCaptchaV3Service } from 'ngx-captcha';

import { TranslateService } from '@ngx-translate/core';
import { FileService } from '../../services/file.service';
import { CpSweetalertService } from '../../services/cp-sweetalert.service';

import { RESTRequest } from '../../models/restrequest.model';
import { HttpClient, HttpParams } from '@angular/common/http';

import { RESTResponse } from '../../models/restresponse';
import { MinorAlterationService } from '../../services/minor-alteration.service';
import { ClaimService } from '../../services/claim.service';


import { FormBuilder, FormGroup, FormControl, Validators, ValidationErrors, ValidatorFn, NgForm } from '@angular/forms';
import { MoneyList } from '../../models/money-list.model';
import { FileProgress } from '../../models/file-progress';
import { LocalService } from '../../local.service';
import { FileClass } from '../../models/fileClass';
import { ReqUpdateOccup } from '../../models/req-update-occup.model';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { UserService } from '../../services/user.service';
import { CustomerService } from '../../services/customer.service';
import { NotifWaEmailSms } from '../../services/NotifWaEmailSms.service';
import { APIService } from '../../services/api.service';

@Component({
  selector: 'app-update-occup',
  templateUrl: './update-occup.component.html',
  styleUrls: ['./update-occup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [Config, AuditTrail, ClaimService, MinorAlterationService, FileService, UserService]
})
export class UpdateOccupComponent implements OnInit {

  public googleRecaptchaKey: any = '';
  public swalData: any = {};
  isChecked = false;

  responseHP: any;
  activationCountdown: any;
  activationTime = 5;

  otpPass: boolean = false;
  otpFail: boolean = false;
  process: boolean = false;
  otpNull: boolean = false;
  startOTP: number = 0;
  endOTP: number = 3;
  maxOtp: boolean = false;
  timeEnd: boolean = false;
  displayOTP: boolean = false;

  kodeOTP: any;
  capthaTemp: any;
  policyCode: any;
  perOTP: any;
  message = 'Kode OTP berhasil diverifikasi!';

  modalConfig: ModalOptions = { backdrop: 'static', keyboard: false, ignoreBackdropClick: true };
  @ViewChild('myOpen', { static: false }) myOpen: ElementRef<HTMLElement>;
  @ViewChild('closeDialog', { static: false }) closeDialog: ElementRef<HTMLElement>;

  disclaimer = new FormControl(false);

  formCountError: { count: number; form: string[] } = {
    count: 0,
    form: []
  };

  public errors: any[] = [];

  moneys: any[] = [];
  policyInvestmentData: any = [];
  policyDetailData: any = {};

  reqRequest: ReqUpdateOccup = new ReqUpdateOccup();

  loadingButton: boolean = false;

  loading = true;
  identityCardFileProgress: FileProgress = { loading: false, progress: 0 };

  formStatus: {
    occupation: boolean;
    salary: boolean;
  } = {
      occupation: false,
      salary: false
    };

  identityCardFileName: string = null;

  fileClass: FileClass = new FileClass();
  private imageSrc: string = '';
  public data: any = {};
  public userId: any = '';
  errorMessage: string;
  params: any;

  target: any;
  date: any;
  ext: any;
  dateFormat: any;
  newFileName: any;

  occupation: any;
  annualIncome: any;
  currency: any;
  occupationCode: any;


  @ViewChild('reqUpdate', { static: false }) reqUpdate: NgForm;

  curr: any;
  formGroup: FormGroup;
  listCategoriJob: any = [];

  constructor(private cnf: Config,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private http: HttpClient,
    private auditTrail: AuditTrail,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private claim: ClaimService,
    private minorAlterationService: MinorAlterationService,
    private formBuilder: FormBuilder,
    private file: FileService,
    private translate: TranslateService,
    private cpSweetalertService: CpSweetalertService,
    private localService: LocalService,
    private customerService: CustomerService,
    private user: UserService,
    private apiService: APIService,
    private notif: NotifWaEmailSms) {
    this.route.params.subscribe(params => (this.params = params));
    this.buildForm();
  }

  ngOnInit() {
    this.auditTrail.saveLog('Change Annual Income & Occupation', 'Open');
    this.googleRecaptchaKey = this.cnf.googleRecaptchaKey;
    this.titleService.setTitle(this.cnf.prefixTitle + 'Change Annual Income & Occupation' + this.cnf.postfixTitle);
    this.userId = this.localService.getJsonValue('userid');

    this.customerService.getCategoriJob().subscribe(
      (res: any) => {
        if (res.body.success) {
          this.listCategoriJob = res.body.data;
        }
      },
      err => {
        console.error(err);
      }
    );

    this.policyDetail()
      .subscribe((res: any) => {
        if (res.body.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.policyDetailData = decryptData[0].policyInformation_detail[0];
          this.customerService.getInsuredByPolicy(this.params.policyNo).subscribe((res: any) => {
            if (res.status == 200) {
              this.occupation = res.body.data.occupation;
              this.annualIncome = +res.body.data.annualIncome;
              this.currency = res.body.data.currency;
              this.occupationCode = res.body.data.occupationCode;
              this.formGroup.controls.occupation.setValue(this.occupationCode);
              this.formGroup.controls.salary.setValue(this.annualIncome);
              this.formGroup.controls.currency.setValue(this.currency);
            }
          });

          this.claim.getFormatMoney().subscribe(
            (res: any) => {
              const response: RESTResponse<MoneyList[]> = res.body;
              if (response.success) {

                for (let entry of response.data) {

                  let newMoneys = {
                    value: entry.moneyCode,
                    label: entry.moneyCode
                  };
                  this.moneys.push(newMoneys);
                }
                this.formGroup.controls.currency.setValue(this.currency);
              }
            },
            err => {
              console.error(err);
            }
          );

          this.loading = false;
        } else {
          this.router.navigate(['/404']);
        }
      });
  }

  /**
  * @desc Build formGroup
  */
  buildForm() {
    this.formGroup = this.formBuilder.group({
      policyHolderName: new FormControl({ value: null, disabled: true }, Validators.required),
      policyNo: new FormControl({ value: null, disabled: true }, Validators.required),
      identityCardPhoto: new FormControl(null, [Validators.required]),
      currency: new FormControl({ value: null, disabled: true }),
      salary: new FormControl({ value: null, disabled: true }),
      occupation: new FormControl({ value: null, disabled: true }),
      disclaimer: this.disclaimer
    });
  }

  // numberWithCommas(event) {
  //   const sal = event.target.value;
  //   console.log(sal);
  //   var parts = sal.toString().split(".");
  //   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   (this.formGroup.controls.salary as FormControl).setValue(parts.join("."));
  //   console.log(parts.join("."));
  //   //string.replace(/,(?=.*\.\d+)/g, '');
  // }

  /**
   * @desc event when "Ubah" clicked in every form input
   * @param type {address, homeTel, mobile, officeTel, email, dispatchType}
   */
  onChangeClicked(type: string) {
    const formStatusClone = { ...this.formStatus };
    if (type === 'salary') {
      formStatusClone.salary = !formStatusClone.salary;
      const salaryControl: FormControl = this.formGroup.controls.salary as FormControl;
      const currencyControl: FormControl = this.formGroup.controls.currency as FormControl;
      if (formStatusClone.salary) {
        salaryControl.enable();
        salaryControl.setValidators([Validators.required, Validators.max(100000000000000), Validators.min(0)]);
        currencyControl.enable();
        currencyControl.setValidators([Validators.required]);
      } else {
        currencyControl.disable();
        salaryControl.disable();
        salaryControl.setValidators(null);
        currencyControl.setValidators(null);
      }

      if (this.formStatus.salary) {
        this.formGroup.controls.salary.setValue(this.annualIncome);
        this.formGroup.controls.currency.setValue(this.currency);
      } else {
        salaryControl.reset();
        currencyControl.reset();
      }
    } else if (type === 'occupation') {
      formStatusClone.occupation = !formStatusClone.occupation;
      const occupationControl: FormControl = this.formGroup.controls.occupation as FormControl;
      if (formStatusClone.occupation) {
        occupationControl.enable();
        occupationControl.setValidators([Validators.required]);
      } else {
        occupationControl.disable();
        occupationControl.setValidators(null);
      }

      if (this.formStatus.occupation) {
        this.formGroup.controls.occupation.setValue(this.occupation);
      } else {
        occupationControl.reset();
      }
    }
    this.formStatus = formStatusClone;
  }

  policyDetail() {
    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/policy_information/detail/policy/' + this.params.policyNo);
  }

  /**
   * @desc on submit 
   */
  onSubmit() {
    if (this.isChecked) {
      this.cnf.showRecaptchaBadge();
      this.loadingButton = true;
      this.reCaptchaV3Service.execute(this.cnf.googleRecaptchaKeyv3, 'reqpolis', captchaToken => {
        const formValue = this.formGroup.value;

        if (formValue.occupation != null) {
          var result = this.listCategoriJob.find((obj: any) => obj.jobCateId == formValue.occupation);
          this.reqRequest.occupation = result.class1;
        }
        this.reqRequest.policyCode = this.params.policyNo;
        this.reqRequest.currency = formValue.currency;
        this.reqRequest.salary = formValue.salary;
        this.reqRequest.policyHolderName = this.policyDetailData.policyHolderName;
        this.reqRequest.occupationCode = formValue.occupation;

        const beforeSalary = this.currency + " " + this.annualIncome;
        const afterSalary = formValue.currency + " " + formValue.salary;
        if (formValue.salary != null) {
          if (beforeSalary != afterSalary) {
            this.auditTrail.auditTrailBefore.annualIncome = beforeSalary;
            this.auditTrail.auditTrailAfter.annualIncome = afterSalary;
          }
        }
        if (formValue.occupation != null) {
          if (this.occupation != formValue.occupation) {
            this.auditTrail.auditTrailBefore.occupation = this.occupation;
            this.auditTrail.auditTrailAfter.occupation = formValue.occupation;
          }
        }

        this.policyCode = this.params.policyNo;
        this.capthaTemp = captchaToken;

        this.user.requestOTP(this.userId, 'occupation', 'sms').subscribe((res: any) => {
          this.startOTP = (+res.body.datas.countotp) + 1;
          this.process = false;
          this.kodeOTP = null;
          if (this.startOTP <= 3) {
            if (res.body.datas.handphone != null || res.body.datas.email) {
              this.activationCountdown = new Date().setMinutes(new Date().getMinutes() + this.activationTime);
              this.responseHP = res.body.datas.handphone;
              this.perOTP = this.startOTP + '/' + this.endOTP;
              if (this.startOTP == this.endOTP) {
                this.maxOtp = true;
              }
              let el: HTMLElement = this.myOpen.nativeElement;
              el.click();
            }
          } else {
            this.message = res.body.datas.msg;
            this.displayOTP = true;
            this.otpFail = true;
            let el: HTMLElement = this.myOpen.nativeElement;
            el.click();
          }
        }, err => {
          console.log(err.message);
        });
      });
    } else {
      this.cpSweetalertService.show('_eclaim_disclaimer_warning', 'warning');
    }
  }

  /**
   * @desc on KTP selected function
   */
  onIdentityCardFileSelect(event: any) {
    var file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file);
    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }

  }

  _handleReaderLoaded(e: any, event: any) {
    let reader2 = event.target;
    this.imageSrc = reader2.result;
    this.fileClass.fileDownloadUri = this.imageSrc;
    this.fileClass.fileName = this.newFileName;
    this.fileClass.fileType = this.ext;
    this.fileClass.size = this.target.size;
    this.fileClass.userId = this.userId;
    this.file.uploadWithNProgress(this.fileClass).subscribe(
      (res: any) => {
        const response = res.body;
        if (response.success) {
          const identityCardPhoto = response.data.fileDownloadUri;
          this.reqRequest.identityCard = identityCardPhoto;
          this.identityCardFileName = this.newFileName;

          this.cpSweetalertService.show('Upload File Berhasil.', 'success', false);
        }
      }
      , err => {
        console.log(err);
        this.formGroup.get('identityCardPhoto').setValue(null);
        this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
      },
      () => {
        this.identityCardFileProgress = { loading: false, progress: 0 };
        this.formGroup.get('identityCardPhoto').enable();
      }
    );
  }

  /**
   * @desc create custom random char for file-name
   */
  makecustom(length: any) {
    var a = new Date();

    var uuid = require("uuid");
    var id = btoa(a + '').slice(-7, -2) + '' + btoa(uuid.v4()).slice(-7, -2);

    return id;
  }

  /**
   * @desc Reset Form
   */
  resetForm() {
    this.reqUpdate.resetForm();
    this.reqRequest = new ReqUpdateOccup();
  }
  doGoHome() {
    this.router.navigate(['/']);
  }

  /**
   * @desc on disclaimer checbox change
   */
  changeCheck(event: any) {
    // reset form Error Counter
    this.formCountError.count = 0;
    this.formCountError.form.length = 0;

    this.errorAllCounter(this.formGroup);

    const count = this.formCountError.count;

    if (count === 0) {
      this.isChecked = true;
      this.disclaimer.setValue(true);
    } else {
      this.isChecked = false;
      this.disclaimer.setValue(false);
      this.validateAllFormFields(this.formGroup);
      if (this.formCountError.count === 1 && this.formCountError.form[0] === 'identityCardPhoto') {
        this.cpSweetalertService.show('_minor_identity_card_scan_warning', 'warning');
      } else {
        this.cpSweetalertService.show('Permintaan tidak dapat diproses, mohon lengkapi formulir dan mengunggah dokumen', 'warning');
      }
    }
  }

  /**
   * @desc fire validate form
   * @param formGroup
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * @desc Check all error form input
   */
  errorAllCounter(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormControl) {
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(_ => {
            this.formCountError.count++;
            this.formCountError.form.push(key);
          });
        }
      } else if (control instanceof FormGroup) {
        this.errorAllCounter(control);
      }
    });
    return this.formCountError;
  }

  activationTimeout() {
    this.timeEnd = true;
  }

  sendAgin() {
    this.kodeOTP = null;
    this.process = true;
    this.user.requestOTP(this.userId, 'occupation', 'sms').subscribe((res: any) => {
      console.log(res)
      this.startOTP = (+res.body.datas.countotp) + 1;
      if (this.startOTP <= 3) {
        this.timeEnd = false;
        this.process = false;
        this.otpPass = true;
        this.message = 'Kode OTP berhasil dikirim ulang!';
        this.responseHP = res.body.datas.handphone;
        this.perOTP = this.startOTP + '/' + this.endOTP;
        if (this.startOTP == this.endOTP) {
          this.maxOtp = true;
        }
        this.activationCountdown = new Date().setMinutes(new Date().getMinutes() + 3);
        setTimeout(() => {
          this.otpPass = false;
        }, 200);
      } else {
        this.message = res.body.datas.msg;
        this.displayOTP = true;
        this.otpFail = true;
        let el: HTMLElement = this.myOpen.nativeElement;
        el.click();
      }
    }, (err: any) => {
      console.log(err.message);
    });
  }

  submitOTP() {
    if (this.kodeOTP != null) {
      this.user.checkOTP(this.userId, 'occupation', this.kodeOTP).subscribe((res: any) => {
        if (res.body.datas.corret == "Y") {
          this.message = 'Kode OTP berhasil diverifikasi!';
          this.otpPass = true;
          this.process = true;
          setTimeout(() => {
            let el: HTMLElement = this.closeDialog.nativeElement;
            el.click();
            const req = new RESTRequest<ReqUpdateOccup>();
            req.appid = this.cnf.appid;
            req.appkey = this.cnf.appkey;
            req.data = this.reqRequest;
            req.apptype = 'web';
            req.token = this.capthaTemp;
            this.minorAlterationService
              .submitReqUpdate(req, this.userId)
              .subscribe(
                (res: any) => {
                  const response = res.body;
                  if (response.success) {
                    this.cpSweetalertService.show('Terima kasih, permintaan anda sudah kami terima.', 'success');
                    this.auditTrail.saveLog('Change Annual Income & Occupation', 'Submit Success#' + this.policyCode);
                    this.notif.sendNotification(this.policyDetailData.email, this.policyDetailData.mobilePhone, this.policyCode, "Ubah Penghasilan/Pekerjaan");
                    this.resetForm();
                  }
                },
                err => {
                  if (err.status === 400) {
                    const msg = err.error;
                    const message = this.translate.instant(msg.message);
                    this.cpSweetalertService.show(message, 'error');
                    this.auditTrail.saveLog('Change Annual Income & Occupation', 'Submit Failed#' + this.policyCode + ' #' + msg.message);
                  } else {
                    console.error(err);
                    const msg = err.error;
                    const message = this.translate.instant(msg.message);
                    this.cpSweetalertService.show(message, 'error');
                    this.auditTrail.saveLog('Change Annual Income & Occupation', 'Submit Failed#' + this.policyCode + ' #' + msg.message);
                  }
                }
              )
              .add(() => {
                this.loadingButton = false;
                this.process = false;
              });
            this.otpPass = false;
          }, 500);
        } else {
          this.otpFail = true;
          this.message = 'Kode OTP yang anda masukkan tidak valid';
        }
      }, (err: any) => {
        console.log(err.message);
      });
    } else {
      this.otpNull = true;
    }
  }

  closeOTP() {
    this.loadingButton = false;
    let el: HTMLElement = this.closeDialog.nativeElement;
    el.click();
  }

}
