import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { CpSweetalertService } from '../../services/cp-sweetalert.service';
import { AuditTrail } from '../../audit-trail';
import { Config } from '../../config';
import { CustomerService } from '../../services/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MinorAlterationService } from '../../services/minor-alteration.service';
import { RESTResponse } from '../../models/restresponse';
import { ReqModel } from '../../models/reqModel';
import { LocalService } from '../../local.service';
import { FileClass } from '../../models/fileClass';
import { FileService } from '../../services/file.service';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { UserService } from '../../services/user.service';
import { RESTRequest } from '../../models/restrequest.model';
import { ChangeRequest } from '../../models/ChangeRequest';
import { NotifWaEmailSms } from '../../services/NotifWaEmailSms.service';
import { PremiumHolidayDTO } from '../../models/premium-holiday-dto';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-premium-holiday',
  templateUrl: './premium-holiday.component.html',
  styleUrls: ['./premium-holiday.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [Config, AuditTrail, MinorAlterationService, FileService, UserService]
})
export class PremiumHolidayComponent implements OnInit {

  @ViewChild('ngForm', { static: false }) ngForm: NgForm;
  formGroup: FormGroup;
  loading: boolean = false;
  loadingPolicy: boolean = false;
  loadingButton: boolean = false;
  policies: any = [];
  freqPaymentList: any = [];
  googleRecaptchaKey: any = '';
  userId: any = '';
  params: any;
  policyDetailData: any = {};
  reqData: ReqModel = new ReqModel();
  identityCardFileName: string = null;

  fileClass: FileClass = new FileClass();
  private imageSrc: string = '';
  target: any;
  date: any;
  ext: any;
  dateFormat: any;
  newFileName: any;

  disclaimer = new FormControl(false);
  isChecked = false;
  monthBefore: number = 0;
  loadingData: boolean = false;

  formCountError: { count: number; form: string[] } = {
    count: 0,
    form: []
  };

  note: any;

  otpPass: boolean = false;
  otpFail: boolean = false;
  process: boolean = false;
  otpNull: boolean = false;
  startOTP: number = 0;
  endOTP: number = 3;
  maxOtp: boolean = false;
  timeEnd: boolean = false;
  displayOTP: boolean = false;

  kodeOTP: any;
  capthaTemp: any;
  policyCode: any;
  perOTP: any;
  message = 'Kode OTP berhasil diverifikasi!';
  responseHP: any;
  activationCountdown: any;
  activationTime = 5;

  modalConfig: ModalOptions = { backdrop: 'static', keyboard: false, ignoreBackdropClick: true };
  @ViewChild('myOpen', { static: false }) myOpen: ElementRef<HTMLElement>;
  @ViewChild('closeDialog', { static: false }) closeDialog: ElementRef<HTMLElement>;
  @ViewChild('myOTP', { static: false }) myOTP: ElementRef<HTMLElement>;
  @ViewChild('tempUseCuti', { static: false }) tempUseCuti: ElementRef<HTMLElement>;

  reqRequest: ChangeRequest = new ChangeRequest();

  fundList: any = [];
  fundCustomerList: any = [];
  dueDatePolicy: any;
  tempDueDate: Date = new Date();
  htmlToAdd: any;
  htmlToRemove: any;

  listUsePremiumHoliday: PremiumHolidayDTO[] = [];
  listUnUsePremiumHoliday: PremiumHolidayDTO[] = [];

  listTempUsePremiumHoliday: PremiumHolidayDTO[] = [];
  listTempInsertUsePremiumHoliday: PremiumHolidayDTO[] = [];

  listOnCheckPremium: PremiumHolidayDTO[] = [];

  typeSubmit: string = 'add';
  isStart: boolean = true;
  listTempPremium: any = [];


  constructor(
    private formBuilder: FormBuilder,
    private minorAlterationService: MinorAlterationService,
    private translate: TranslateService,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private cpSweetalertService: CpSweetalertService,
    private customerService: CustomerService,
    private localService: LocalService,
    private route: ActivatedRoute,
    private cnf: Config,
    private file: FileService,
    private user: UserService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private notif: NotifWaEmailSms,
    private router: Router,

  ) {
    this.route.params.subscribe(params => (this.params = params));
    this.buildForm()
  }

  ngOnInit() {
    this.loadingData = false;
    this.auditTrail.saveLog('Premium Holiday', 'Open');
    this.loadingPolicy = true;
    this.googleRecaptchaKey = this.cnf.googleRecaptchaKey;
    this.titleService.setTitle(this.cnf.prefixTitle + 'Premium Holiday' + this.cnf.postfixTitle);
    this.userId = this.localService.getJsonValue('userid');
    this.minorAlterationService.getMinorAlterationData(this.userId).subscribe(
      (res: any) => {
        const response: RESTResponse<ReqModel> = res.body;
        if (response.success) {
          this.reqData = { ...response.data };
          if (this.reqData.policyNumbers.length > 0) {
            for (const policyNumber of this.reqData.policyNumbers) {
              const newPolicy = {
                value: policyNumber.policyCode,
                label: policyNumber.policyCode
              };
              this.policies.push(newPolicy);
            }
            this.formGroup.controls.policyNo.setValue(this.params.policyNo);

            this.customerService.getPremiumHoliday(this.params.policyNo).subscribe((res) => {
              if (res.status == 200) {
                if (res.body.success) {
                  this.listTempPremium = res.body.data;
                  console.log(this.listTempPremium.length);
                  if (this.listTempPremium.length > 0) {
                    for (var obj of this.listTempPremium) {
                      const dto = new PremiumHolidayDTO();
                      dto.id = obj.id;
                      dto.policyCode = obj.policyCode;
                      dto.flag = obj.flag;
                      dto.premiumHolidayDate = obj.premiumHolidayDate;
                      dto.stringDate = ddmmyyyy(obj.premiumHolidayDate);
                      this.listTempUsePremiumHoliday.push(dto);
                      this.listTempInsertUsePremiumHoliday.push(dto);
                    }
                  }

                  this.detailPersonal(this.params.policyNo);
                  this.loadingPolicy = false;
                }
              }
            });
          }
        }
      }
    );

    function ddmmyyyy(nextDate: any) {
      const today = new Date(nextDate);
      const yyyy = today.getFullYear();
      let mm = "" + (today.getMonth() + 1); // Months start at 0!
      let dd = "" + today.getDate();

      if (Number(dd) < 10) dd = '0' + dd;
      if (Number(mm) < 10) mm = '0' + mm;

      return dd + '/' + mm + '/' + yyyy;
    }
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      policyNo: new FormControl(null, Validators.required),
      policyHolderName: new FormControl({ value: null, disabled: true }, Validators.required),
      mainLifeAssured: new FormControl({ value: null, disabled: true }, Validators.required),
      freqPayment: new FormControl({ value: null, disabled: true }, Validators.required),
      identityCardPhoto: new FormControl(null, Validators.required),
      disclaimer: this.disclaimer
    });
  }

  onSubmit() {
    if (this.isChecked) {
      this.listUsePremiumHoliday = [];
      this.listUnUsePremiumHoliday = [];
      const formValue = this.formGroup.value;
      this.policyCode = formValue.policyNo;
      if (this.listTempUsePremiumHoliday.length > 0) {

        var change = false;

        if (this.listTempInsertUsePremiumHoliday.length > 0) {

          //check unuse
          this.listTempInsertUsePremiumHoliday.forEach((data: any, index: any) => {
            var check = false;
            this.listTempUsePremiumHoliday.forEach((val, id) => {
              if (data.stringDate == val.stringDate) {
                check = true;
              }
            });
            if (!check) {
              this.listUnUsePremiumHoliday.push(data);
            }
          });

          //check use
          if (this.listUnUsePremiumHoliday.length > 0) {
            this.listTempUsePremiumHoliday.forEach((data: any, index: any) => {
              var check = false;
              this.listUnUsePremiumHoliday.forEach((val, id) => {
                if (data.stringDate == val.stringDate) {
                  check = true;
                }
              });

              if (!check) {
                this.listUsePremiumHoliday.push(data);
              }

            });
          } else {
            this.listUsePremiumHoliday = this.listTempUsePremiumHoliday;
          }

          if (this.listTempInsertUsePremiumHoliday.length == this.listTempUsePremiumHoliday.length) {

            var check2 = false;
            this.listTempInsertUsePremiumHoliday.forEach((data: any, index: any) => {

              var sts = false;
              this.listTempUsePremiumHoliday.forEach((val, id) => {
                if (data.stringDate == val.stringDate) {
                  sts = true;
                }
              });

              if (!sts) {
                check2 = true;
              }

            });

            if (check2) {
              change = true;
            }
          } else {
            change = true;
          }
        } else {
          this.listUsePremiumHoliday = this.listTempUsePremiumHoliday;
          change = true;
        }

        if (change) {

          this.cnf.showRecaptchaBadge();
          this.loadingButton = true;
          this.reCaptchaV3Service.execute(this.cnf.googleRecaptchaKeyv3, 'reqchangepremium_holiday', captchaToken => {

            this.capthaTemp = captchaToken;
            this.reqRequest.premiumHoliday = this.listTempUsePremiumHoliday;
            this.reqRequest.unPremiumHoliday = this.listUnUsePremiumHoliday;
            this.reqRequest.typePremium = this.typeSubmit;
            this.reqRequest.freqBefore = this.policyDetailData.paymentFrequency;
            this.reqRequest.policyCode = this.policyCode;
            this.reqRequest.typeRequest = "premium_holiday";
            if (environment.production) {
              this.reqRequest.subject = "[NOTIFICATION] - New Request Premium Holiday - " + this.policyCode + " - " + this.policyDetailData.policyHolderName;
            } else {
              this.reqRequest.subject = "[UAT] - New Request Premium Holiday - " + this.policyCode + " - " + this.policyDetailData.policyHolderName;
            }

            this.reqRequest.title = "You Have Received New Request Premium Holiday from Customer Corner"
            this.reqRequest.policyHolderBefore = this.policyDetailData.policyHolderName;
            this.reqRequest.mainLifeAssured = this.policyDetailData.mainLifeAssured;

            this.user.requestOTP(this.userId, 'premium_holiday', 'sms').subscribe((res: any) => {
              this.startOTP = (+res.body.datas.countotp) + 1;
              this.process = false;
              this.kodeOTP = null;
              if (this.startOTP <= 3) {
                if (res.body.datas.handphone != null) {
                  this.activationCountdown = new Date().setMinutes(new Date().getMinutes() + this.activationTime);
                  this.responseHP = res.body.datas.handphone;
                  this.perOTP = this.startOTP + '/' + this.endOTP;
                  if (this.startOTP == this.endOTP) {
                    this.maxOtp = true;
                  }
                  let el: HTMLElement = this.myOpen.nativeElement;
                  el.click();
                  let ell: HTMLElement = this.myOTP.nativeElement;
                  ell.focus();
                }
              } else {
                this.message = res.body.datas.msg;
                this.displayOTP = true;
                this.otpFail = true;
                let el: HTMLElement = this.myOpen.nativeElement;
                el.click();
              }
            }, err => {
              console.log(err.message);
            });
          });
        } else {
          this.cpSweetalertService.show('Silahkan centang pada tanggal yang diinginkan', 'warning');
        }
      } else {
        this.cpSweetalertService.show('Silahkan centang pada tanggal yang diinginkan', 'warning');
      }
    } else {
      this.cpSweetalertService.show('_eclaim_disclaimer_warning', 'warning');
    }
  }

  choicePolicy(data: any) {
    this.customerService.getPremiumHoliday(this.params.policyNo).subscribe((res) => {
      if (res.status == 200) {
        if (res.body.success) {
          this.listTempPremium = res.body.data;

          if (this.listTempPremium.length > 0) {
            for (var obj of this.listTempPremium) {
              const dto = new PremiumHolidayDTO();
              dto.id = obj.id;
              dto.policyCode = obj.policyCode;
              dto.flag = obj.flag;
              dto.premiumHolidayDate = obj.premiumHolidayDate;
              dto.stringDate = ddmmyyyy(obj.premiumHolidayDate);
              this.listTempUsePremiumHoliday.push(dto);
              this.listTempInsertUsePremiumHoliday.push(dto);
            }
          }

          this.detailPersonal(data.value);
        }
      }
    });

    function ddmmyyyy(nextDate: any) {
      const today = new Date(nextDate);
      const yyyy = today.getFullYear();
      let mm = "" + (today.getMonth() + 1); // Months start at 0!
      let dd = "" + today.getDate();

      if (Number(dd) < 10) dd = '0' + dd;
      if (Number(mm) < 10) mm = '0' + mm;

      return dd + '/' + mm + '/' + yyyy;
    }
  }

  detailPersonal(policyNo: any) {
    this.customerService.getPolicyInformation(policyNo, this.cnf.token).subscribe((res: any) => {
      if (res.body.status == 100) {
        let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
        this.policyDetailData = decryptData[0].policyInformation_detail[0];
        this.formGroup.controls.policyHolderName.setValue(this.policyDetailData.policyHolderName);
        this.formGroup.controls.mainLifeAssured.setValue(this.policyDetailData.mainLifeAssured);
        this.formGroup.controls.freqPayment.setValue(this.policyDetailData.paymentFrequency);
        this.dueDatePolicy = this.policyDetailData.dueDate;

        var limit = 0;
        var conts = 3;
        var monthConst = conts * 12;

        var yearly = 12;
        var halfly = 6;
        var quarterly = 3;
        var month = 1;

        if (this.policyDetailData.paymentFrequency == "Yearly") {
          limit = monthConst / yearly;
        }
        if (this.policyDetailData.paymentFrequency == "Half Yearly") {
          limit = monthConst / halfly;
        }
        if (this.policyDetailData.paymentFrequency == "Quarterly") {
          limit = monthConst / quarterly;
        }
        if (this.policyDetailData.paymentFrequency == "Monthly") {
          limit = monthConst / month;
        }

        if (limit != 0) {
          var nextDate = null;
          this.listOnCheckPremium = [];
          for (var i = 0; i < limit; i++) {
            if (nextDate == null) {
              nextDate = new Date(this.dueDatePolicy);
            } else {
              var dueDate = new Date(nextDate);
              nextDate = dueDate.setMonth(dueDate.getMonth() + 3);
            }
            const dto = new PremiumHolidayDTO();
            dto.policyCode = this.params.policyNo;
            dto.premiumHolidayDate = new Date(yyyymmdd(nextDate));
            dto.stringDate = ddmmyyyy(nextDate);
            this.listOnCheckPremium.push(dto);
          }

          this.listOnCheckPremium.forEach((val, idx) => {
            this.listOnCheckPremium[idx].isCheck = false;
            this.listOnCheckPremium[idx].policyCode = this.params.policyNo;

            this.listTempUsePremiumHoliday.forEach((data: any, id: any) => {
              if (val.stringDate == data.stringDate) {
                this.listOnCheckPremium[idx].isCheck = true;
              }
            });
          });

        }

        function ddmmyyyy(nextDate: any) {
          const today = new Date(nextDate);
          const yyyy = today.getFullYear();
          let mm = "" + (today.getMonth() + 1); // Months start at 0!
          let dd = "" + today.getDate();

          if (Number(dd) < 10) dd = '0' + dd;
          if (Number(mm) < 10) mm = '0' + mm;

          return dd + '/' + mm + '/' + yyyy;
        }

        function yyyymmdd(nextDate: any) {
          const today = new Date(nextDate);
          const yyyy = today.getFullYear();
          let mm = "" + (today.getMonth() + 1);
          let dd = "" + today.getDate();

          if (Number(dd) < 10) dd = '0' + dd;
          if (Number(mm) < 10) mm = '0' + mm;

          return yyyy + '-' + mm + '-' + dd;
        }
      }
    });
  }

  onIdentityCardFileSelect(event: any) {
    var file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();
    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file);
    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  _handleReaderLoaded(e: any, event: any) {
    let reader2 = event.target;
    this.imageSrc = reader2.result;
    this.fileClass.fileDownloadUri = this.imageSrc;
    this.fileClass.fileName = this.newFileName;
    this.fileClass.fileType = this.ext;
    this.fileClass.size = this.target.size;
    this.fileClass.userId = this.userId;
    this.file.uploadWithNProgress(this.fileClass).subscribe(
      (res: any) => {
        const response = res.body;
        if (response.success) {
          const identityCardPhoto = response.data.fileDownloadUri;
          this.reqRequest.idCard = identityCardPhoto;
          this.identityCardFileName = this.newFileName;

          this.cpSweetalertService.show('Unggah Dokumen Berhasil', 'success', false);
        }
      }
      , (err: any) => {
        console.log(err);
        this.formGroup.get('identityCardPhoto').setValue(null);
        this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
      },
      () => {
        this.formGroup.get('identityCardPhoto').enable();
      }
    );
  }

  makecustom(length: any) {
    var a = new Date();
    var uuid = require("uuid");
    var id = btoa(a + '').slice(-7, -2) + '' + btoa(uuid.v4()).slice(-7, -2);
    return id;
  }

  resetForm() {
    this.ngForm.reset();
    this.note = '';
  }

  changeCheck(event: any) {
    this.formCountError.count = 0;
    this.formCountError.form.length = 0;
    this.errorAllCounter(this.formGroup);
    const count = this.formCountError.count;
    if (count === 0) {
      this.isChecked = true;
      this.disclaimer.setValue(true);
    } else {
      this.isChecked = false;
      this.disclaimer.setValue(false);
      this.validateAllFormFields(this.formGroup);
      if (this.formCountError.count === 1 && this.formCountError.form[0] === 'identityCardPhoto') {
        this.cpSweetalertService.show('_minor_identity_card_scan_warning', 'warning');
      } else {
        this.cpSweetalertService.show('Permintaan tidak dapat diproses, mohon lengkapi formulir dan mengunggah dokumen', 'warning');
      }
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  errorAllCounter(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormControl) {
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(_ => {
            this.formCountError.count++;
            this.formCountError.form.push(key);
          });
        }
      } else if (control instanceof FormGroup) {
        this.errorAllCounter(control);
      }
    });
    return this.formCountError;
  }

  activationTimeout() {
    this.timeEnd = true;
  }

  sendAgin() {
    this.kodeOTP = null;
    this.process = true;
    this.user.requestOTP(this.userId, 'premium_holiday', 'sms').subscribe((res: any) => {
      this.startOTP = (+res.body.datas.countotp) + 1;
      if (this.startOTP <= 3) {
        this.timeEnd = false;
        this.process = false;
        this.otpPass = true;
        this.message = 'Kode OTP berhasil dikirim ulang!';
        this.responseHP = res.body.datas.handphone;
        this.perOTP = this.startOTP + '/' + this.endOTP;
        if (this.startOTP == this.endOTP) {
          this.maxOtp = true;
        }
        this.activationCountdown = new Date().setMinutes(new Date().getMinutes() + 3);
        setTimeout(() => {
          this.otpPass = false;
        }, 200);
        let el: HTMLElement = this.myOTP.nativeElement;
        el.focus();
      } else {
        this.message = res.body.datas.msg;
        this.displayOTP = true;
        this.otpFail = true;
        let el: HTMLElement = this.myOpen.nativeElement;
        el.click();
      }
    }, (err: any) => {
      console.log(err.message);
    });
  }

  submitOTP() {
    if (this.kodeOTP != null) {
      this.user.checkOTP(this.userId, 'premium_holiday', this.kodeOTP).subscribe((res: any) => {
        if (res.body.datas.corret == "Y") {
          this.message = 'Kode OTP berhasil diverifikasi!';
          this.otpPass = true;
          this.process = true;
          setTimeout(() => {
            let el: HTMLElement = this.closeDialog.nativeElement;
            el.click();
            const req = new RESTRequest<ChangeRequest>();
            req.appid = this.cnf.appid;
            req.appkey = this.cnf.appkey;
            req.data = this.reqRequest;
            req.apptype = 'web';
            req.token = this.capthaTemp;
            console.log(req);
            this.customerService
              .changeRequest(req)
              .subscribe(
                (res: any) => {
                  const response = res.body;
                  if (response.success) {
                    this.listOnCheckPremium = [];
                    this.cpSweetalertService.show('Terima kasih, permintaan anda sudah kami terima.', 'success');
                    this.notif.sendNotification(this.policyDetailData.email, this.policyDetailData.mobile, this.policyCode, "Premium Holiday");
                    this.resetForm();
                    this.auditTrail.saveLog('Premium Holiday', 'Submit Success#' + this.policyCode);
                    // this.router.navigate(['/my-data/policy-information/', this.policyCode]);
                  }
                },
                err => {
                  if (err.status === 400) {
                    const msg = err.error;
                    const message = this.translate.instant(msg.message);
                    this.cpSweetalertService.show(message, 'error');
                    this.auditTrail.saveLog('Premium Holiday', 'Submit Failed#' + this.policyCode + ' #' + msg.message);
                  } else {
                    console.error(err);
                    const msg = err.error;
                    const message = this.translate.instant(msg.message);
                    this.cpSweetalertService.show(message, 'error');
                    this.auditTrail.saveLog('Premium Holiday', 'Submit Failed#' + this.policyCode + ' #' + msg.message);
                  }
                }
              )
              .add(() => {
                this.loadingButton = false;
                this.process = false;
              });
            this.otpPass = false;
          }, 500);
        } else {
          this.otpFail = true;
          this.message = 'Kode OTP yang anda masukkan tidak valid';
        }
      }, (err: any) => {
        console.log(err.message);
      });
    } else {
      this.otpNull = true;
    }
  }

  closeOTP() {
    this.loadingButton = false;
    let el: HTMLElement = this.closeDialog.nativeElement;
    el.click();
  }

  getTransalte(key: any) {
    var result = "";
    this.translate.get(this.policyDetailData.paymentFrequency).subscribe((res: any) => {
      result = res;
    });
    return result;
  }

  useCuti(data: any) {
    if (data.target.value) {
      var li = "";
      var nextDate = null;
      this.listUsePremiumHoliday = [];
      for (var i = 0; i < data.target.value; i++) {
        if (nextDate == null) {
          nextDate = new Date(this.dueDatePolicy);
        } else {
          var dueDate = new Date(nextDate);
          nextDate = dueDate.setMonth(dueDate.getMonth() + 3);
        }
        li += '<li>' + ddmmyyyy(nextDate) + '</li>';

        const dto = new PremiumHolidayDTO();
        dto.policyCode = this.params.policyNo;
        dto.premiumHolidayDate = new Date(yyyymmdd(nextDate));
        this.listUsePremiumHoliday.push(dto);
      }
      this.htmlToAdd = li;
    }

    function ddmmyyyy(nextDate: any) {
      const today = new Date(nextDate);
      const yyyy = today.getFullYear();
      let mm = "" + (today.getMonth() + 1); // Months start at 0!
      let dd = "" + today.getDate();

      if (Number(dd) < 10) dd = '0' + dd;
      if (Number(mm) < 10) mm = '0' + mm;

      return dd + '/' + mm + '/' + yyyy;
    }

    function yyyymmdd(nextDate: any) {
      const today = new Date(nextDate);
      const yyyy = today.getFullYear();
      let mm = "" + (today.getMonth() + 1); // Months start at 0!
      let dd = "" + today.getDate();

      if (Number(dd) < 10) dd = '0' + dd;
      if (Number(mm) < 10) mm = '0' + mm;

      return yyyy + '-' + mm + '-' + dd;
    }
  }

  oncheck(event: any, data: any) {
    if (event.checked) {
      data.id = null;
      this.listTempUsePremiumHoliday.push(data);
    } else {
      this.listTempUsePremiumHoliday.forEach((val: any, idx: any) => {
        if (val.stringDate == data.stringDate) {
          this.listTempUsePremiumHoliday.splice(idx, 1);
        }
      });
    }
    console.log(this.listTempUsePremiumHoliday);
  }

}
