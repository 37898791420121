import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { CpSweetalertService } from '../../services/cp-sweetalert.service';
import { AuditTrail } from '../../audit-trail';
import { Config } from '../../config';
import { CustomerService } from '../../services/customer.service';
import { ActivatedRoute } from '@angular/router';
import { MinorAlterationService } from '../../services/minor-alteration.service';
import { RESTResponse } from '../../models/restresponse';
import { ReqModel } from '../../models/reqModel';
import { LocalService } from '../../local.service';
import { FileClass } from '../../models/fileClass';
import { FileService } from '../../services/file.service';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { UserService } from '../../services/user.service';
import { RESTRequest } from '../../models/restrequest.model';
import { ChangeRequest } from '../../models/ChangeRequest';
import { BeneDesignationDto } from '../../models/bene-designation-dto.model';
import { NotifWaEmailSms } from '../../services/NotifWaEmailSms.service';


@Component({
  selector: 'app-update-policy-holder',
  templateUrl: './update-policy-holder.component.html',
  styleUrls: ['./update-policy-holder.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [Config, AuditTrail, MinorAlterationService, FileService, UserService]
})
export class UpdatePolicyHolderComponent implements OnInit {

  @ViewChild('ngForm', { static: false }) ngForm: NgForm;
  formGroup: FormGroup;
  loading: boolean = false;
  loadingPolicy: boolean = false;
  loadingButton: boolean = false;
  policies: any = [];
  freqPaymentList: any = [];
  googleRecaptchaKey: any = '';
  userId: any = '';
  params: any;
  policyDetailData: any = {};
  reqData: ReqModel = new ReqModel();
  identityCardFileName1: string = "Cari Dokumen...";
  identityCardFileName2: string = "Cari Dokumen...";
  identityCardFileName3: string = "Cari Dokumen...";
  identityCardFileName4: string = "Cari Dokumen...";
  identityCardFileName5: string = "Cari Dokumen...";
  identityCardFileName6: string = "Cari Dokumen...";
  identityCardFileName7: string = "Cari Dokumen...";

  fileClass: FileClass = new FileClass();
  private imageSrc: string = '';
  target: any;
  date: any;
  ext: any;
  dateFormat: any;
  newFileName: any;

  disclaimer = new FormControl(false);
  isChecked = false;
  monthBefore: number = 0;
  loadingData: boolean = false;

  formCountError: { count: number; form: string[] } = {
    count: 0,
    form: []
  };

  note: any;

  otpPass: boolean = false;
  otpFail: boolean = false;
  process: boolean = false;
  otpNull: boolean = false;
  startOTP: number = 0;
  endOTP: number = 3;
  maxOtp: boolean = false;
  timeEnd: boolean = false;
  displayOTP: boolean = false;

  kodeOTP: any;
  capthaTemp: any;
  policyCode: any;
  perOTP: any;
  message = 'Kode OTP berhasil diverifikasi!';
  responseHP: any;
  activationCountdown: any;
  activationTime = 5;

  modalConfig: ModalOptions = { backdrop: 'static', keyboard: false, ignoreBackdropClick: true };
  @ViewChild('myOpen', { static: false }) myOpen: ElementRef<HTMLElement>;
  @ViewChild('closeDialog', { static: false }) closeDialog: ElementRef<HTMLElement>;
  @ViewChild('myOTP', { static: false }) myOTP: ElementRef<HTMLElement>;

  reqRequest: ChangeRequest = new ChangeRequest();

  tempExpiredCard: any;
  fileNumber: any;
  beneDesignationDtos: BeneDesignationDto[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private minorAlterationService: MinorAlterationService,
    private translate: TranslateService,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private cpSweetalertService: CpSweetalertService,
    private customerService: CustomerService,
    private localService: LocalService,
    private route: ActivatedRoute,
    private cnf: Config,
    private file: FileService,
    private user: UserService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private notif: NotifWaEmailSms

  ) {
    this.route.params.subscribe(params => (this.params = params));
    this.buildForm()
  }

  ngOnInit() {
    this.loadingData = false;
    this.auditTrail.saveLog('Change Policy Holder Name', 'Open');
    this.loadingPolicy = true;
    this.googleRecaptchaKey = this.cnf.googleRecaptchaKey;
    this.titleService.setTitle(this.cnf.prefixTitle + 'Change Policy Holder Name' + this.cnf.postfixTitle);
    this.userId = this.localService.getJsonValue('userid');
    this.minorAlterationService.getMinorAlterationData(this.userId).subscribe(
      (res: any) => {
        const response: RESTResponse<ReqModel> = res.body;
        if (response.success) {
          this.reqData = { ...response.data };
          if (this.reqData.policyNumbers.length > 0) {
            for (const policyNumber of this.reqData.policyNumbers) {
              const newPolicy = {
                value: policyNumber.policyCode,
                label: policyNumber.policyCode
              };
              this.policies.push(newPolicy);
            }
            this.formGroup.controls.policyHolderNameBefore.setValue(this.reqData.policyHolderName);
            this.getBeneDesignation();
            this.loadingPolicy = false;
          }
        }
      }
    );
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      policyHolderNameBefore: new FormControl({ value: null, disabled: true }, Validators.required),
      policyHolderNameAfter: new FormControl({ value: null, disabled: false }, Validators.required),
      designation: new FormControl({ value: null, disabled: false }, Validators.required),
      email: new FormControl({ value: null, disabled: false }, Validators.required),
      mobile: new FormControl({ value: null, disabled: false }, Validators.required),
      identityCardPhoto1: new FormControl(null, Validators.required),
      identityCardPhoto2: new FormControl(null, Validators.required),
      identityCardPhoto3: new FormControl(null),
      identityCardPhoto4: new FormControl(null),
      identityCardPhoto5: new FormControl(null),
      identityCardPhoto6: new FormControl(null),
      identityCardPhoto7: new FormControl(null),
      disclaimer: this.disclaimer
    });
  }

  onSubmit() {
    if (this.isChecked) {
      this.cnf.showRecaptchaBadge();
      this.loadingButton = true;
      this.reCaptchaV3Service.execute(this.cnf.googleRecaptchaKeyv3, 'reqchangepolicy_holder', captchaToken => {
        const formValue = this.formGroup.value;
        this.capthaTemp = captchaToken;
        this.reqRequest.policyCode = this.policyCode;
        this.reqRequest.typeRequest = "policy_holder";
        this.reqRequest.subject = "New Request Change Policy Holder Name - " + this.reqData.policyHolderName;
        this.reqRequest.title = "You Have Received New Request Change Policy Holder Name from Customer Corner"
        this.reqRequest.policyHolderBefore = this.reqData.policyHolderName;
        this.reqRequest.policyHolderAfter = formValue.policyHolderNameAfter;
        this.reqRequest.designation = formValue.designation;
        this.reqRequest.emailPolicyHolderAfter = formValue.email;
        this.reqRequest.mobilePolicyHolderAfter = formValue.mobile;

        if (formValue.policyHolderNameAfter != null) {
          this.auditTrail.auditTrailBefore.policyHolder = this.reqData.policyHolderName;
          this.auditTrail.auditTrailAfter.policyHolder = formValue.policyHolderNameAfter;
        }

        this.user.requestOTP(this.userId, 'policy_holder', 'sms').subscribe((res: any) => {
          this.startOTP = (+res.body.datas.countotp) + 1;
          this.process = false;
          this.kodeOTP = null;
          if (this.startOTP <= 3) {
            if (res.body.datas.handphone != null) {
              this.activationCountdown = new Date().setMinutes(new Date().getMinutes() + this.activationTime);
              this.responseHP = res.body.datas.handphone;
              this.perOTP = this.startOTP + '/' + this.endOTP;
              if (this.startOTP == this.endOTP) {
                this.maxOtp = true;
              }
              let el: HTMLElement = this.myOpen.nativeElement;
              el.click();
              let ell: HTMLElement = this.myOTP.nativeElement;
              ell.focus();
            }
          } else {
            this.message = res.body.datas.msg;
            this.displayOTP = true;
            this.otpFail = true;
            let el: HTMLElement = this.myOpen.nativeElement;
            el.click();
          }
        }, err => {
          console.log(err.message);
        });
      });
    } else {
      this.cpSweetalertService.show('_eclaim_disclaimer_warning', 'warning');
    }
  }

  getBeneDesignation() {
    this.customerService.getBeneDesignation().subscribe(
      (res: any) => {
        const response: RESTResponse<BeneDesignationDto[]> = res.body;
        if (response.success) {
          this.beneDesignationDtos = response.data;
        } else {
          console.log(response.message);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  onIdentityCardFileSelect(event: any, id: any) {
    this.fileNumber = id;
    var file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();
    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file);
    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  _handleReaderLoaded(e: any, event: any) {
    let reader2 = event.target;
    this.imageSrc = reader2.result;
    this.fileClass.fileDownloadUri = this.imageSrc;
    this.fileClass.fileName = this.newFileName;
    this.fileClass.fileType = this.ext;
    this.fileClass.size = this.target.size;
    this.fileClass.userId = this.userId;
    this.file.uploadWithNProgress(this.fileClass).subscribe(
      (res: any) => {
        const response = res.body;
        if (response.success) {
          const identityCardPhoto = response.data.fileDownloadUri;
          if (this.fileNumber == '1') {
            this.identityCardFileName1 = this.newFileName;
            this.reqRequest.idCard = identityCardPhoto;
          }
          if (this.fileNumber == '2') {
            this.identityCardFileName2 = this.newFileName;
            this.reqRequest.idCardNew = identityCardPhoto;
          }
          if (this.fileNumber == '3') {
            this.identityCardFileName3 = this.newFileName;
            this.reqRequest.file1 = identityCardPhoto;
          }
          if (this.fileNumber == '4') {
            this.identityCardFileName4 = this.newFileName;
            this.reqRequest.file2 = identityCardPhoto;
          }
          if (this.fileNumber == '5') {
            this.identityCardFileName5 = this.newFileName;
            this.reqRequest.file3 = identityCardPhoto;
          }
          if (this.fileNumber == '6') {
            this.identityCardFileName6 = this.newFileName;
            this.reqRequest.file4 = identityCardPhoto;
          }
          if (this.fileNumber == '7') {
            this.identityCardFileName7 = this.newFileName;
            this.reqRequest.file5 = identityCardPhoto;
          }
          this.cpSweetalertService.show('Unggah Dokumen Berhasil', 'success', false);
        }
      }
      , (err: any) => {
        console.log(err);
        // this.formGroup.get('identityCardPhoto').setValue(null);
        this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
      },
      () => {
        // this.formGroup.get('identityCardPhoto').enable();
      }
    );
  }

  makecustom(length: any) {
    var a = new Date();
    var uuid = require("uuid");
    var id = btoa(a + '').slice(-7, -2) + '' + btoa(uuid.v4()).slice(-7, -2);
    return id;
  }

  resetForm() {
    this.ngForm.reset();
    this.note = '';
  }

  changeCheck(event: any) {
    this.formCountError.count = 0;
    this.formCountError.form.length = 0;
    this.errorAllCounter(this.formGroup);
    const count = this.formCountError.count;
    if (count === 0) {
      this.isChecked = true;
      this.disclaimer.setValue(true);
    } else {
      this.isChecked = false;
      this.disclaimer.setValue(false);
      this.validateAllFormFields(this.formGroup);
      if (this.formCountError.count === 1 && this.formCountError.form[0] === 'identityCardPhoto') {
        this.cpSweetalertService.show('_minor_identity_card_scan_warning', 'warning');
      } else {
        this.cpSweetalertService.show('Permintaan tidak dapat diproses, mohon lengkapi formulir dan mengunggah dokumen', 'warning');
      }
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  errorAllCounter(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormControl) {
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(_ => {
            this.formCountError.count++;
            this.formCountError.form.push(key);
          });
        }
      } else if (control instanceof FormGroup) {
        this.errorAllCounter(control);
      }
    });
    return this.formCountError;
  }

  activationTimeout() {
    this.timeEnd = true;
  }

  sendAgin() {
    this.kodeOTP = null;
    this.process = true;
    this.user.requestOTP(this.userId, 'policy_holder', 'sms').subscribe((res: any) => {
      this.startOTP = (+res.body.datas.countotp) + 1;
      if (this.startOTP <= 3) {
        this.timeEnd = false;
        this.process = false;
        this.otpPass = true;
        this.message = 'Kode OTP berhasil dikirim ulang!';
        this.responseHP = res.body.datas.handphone;
        this.perOTP = this.startOTP + '/' + this.endOTP;
        if (this.startOTP == this.endOTP) {
          this.maxOtp = true;
        }
        this.activationCountdown = new Date().setMinutes(new Date().getMinutes() + 3);
        setTimeout(() => {
          this.otpPass = false;
        }, 200);
        let el: HTMLElement = this.myOTP.nativeElement;
        el.focus();
      } else {
        this.message = res.body.datas.msg;
        this.displayOTP = true;
        this.otpFail = true;
        let el: HTMLElement = this.myOpen.nativeElement;
        el.click();
      }
    }, (err: any) => {
      console.log(err.message);
    });
  }

  submitOTP() {
    if (this.kodeOTP != null) {
      this.user.checkOTP(this.userId, 'policy_holder', this.kodeOTP).subscribe((res: any) => {
        if (res.body.datas.corret == "Y") {
          this.message = 'Kode OTP berhasil diverifikasi!';
          this.otpPass = true;
          this.process = true;
          setTimeout(() => {
            let el: HTMLElement = this.closeDialog.nativeElement;
            el.click();
            const req = new RESTRequest<ChangeRequest>();
            req.appid = this.cnf.appid;
            req.appkey = this.cnf.appkey;
            req.data = this.reqRequest;
            req.apptype = 'web';
            req.token = this.capthaTemp;
            console.log(req);
            this.customerService
              .changeRequest(req)
              .subscribe(
                (res: any) => {
                  const response = res.body;
                  if (response.success) {
                    this.cpSweetalertService.show('Terima kasih, permintaan anda sudah kami terima.', 'success');
                    this.notif.sendNotification(this.policyDetailData.email, this.policyDetailData.mobile, this.policyCode, "Perubahan Pemegang Polis");
                    this.resetForm();
                    this.identityCardFileName1 = 'Cari Dokumen...';
                    this.identityCardFileName2 = 'Cari Dokumen...';
                    this.identityCardFileName3 = 'Cari Dokumen...';
                    this.identityCardFileName4 = 'Cari Dokumen...';
                    this.identityCardFileName5 = 'Cari Dokumen...';
                    this.identityCardFileName6 = 'Cari Dokumen...';
                    this.identityCardFileName7 = 'Cari Dokumen...';
                    this.auditTrail.saveLog('Change Policy Holder Name', 'Submit Success');
                  }
                },
                err => {
                  if (err.status === 400) {
                    const msg = err.error;
                    const message = this.translate.instant(msg.message);
                    this.cpSweetalertService.show(message, 'error');
                    this.auditTrail.saveLog('Change Policy Holder Name', 'Submit Failed#' + msg.message);
                  } else {
                    console.error(err);
                    const msg = err.error;
                    const message = this.translate.instant(msg.message);
                    this.cpSweetalertService.show(message, 'error');
                    this.auditTrail.saveLog('Change Policy Holder Name', 'Submit Failed#' + msg.message);
                  }
                }
              )
              .add(() => {
                this.loadingButton = false;
                this.process = false;
              });
            this.otpPass = false;
          }, 500);
        } else {
          this.otpFail = true;
          this.message = 'Kode OTP yang anda masukkan tidak valid';
        }
      }, (err: any) => {
        console.log(err.message);
      });
    } else {
      this.otpNull = true;
    }
  }

  closeOTP() {
    this.loadingButton = false;
    let el: HTMLElement = this.closeDialog.nativeElement;
    el.click();
  }

}
