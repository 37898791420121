import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { Observable } from 'rxjs/Observable';

import { ClaimService } from '../../services/claim.service';
import { FileService } from '../../services/file.service';
import { CpSweetalertService } from '../../services/cp-sweetalert.service';

import { RESTResponse } from '../../models/restresponse';
import { Insured } from '../../models/insured.model';
import { MinorAlterationService } from '../../services/minor-alteration.service';

import { MinorAlterationModel } from '../../models/minor-alteration.model';
import { RESTRequest } from '../../models/restrequest.model';

import { FormBuilder, FormGroup, FormControl, Validators, ValidationErrors, ValidatorFn, NgForm } from '@angular/forms';

import { FileProgress } from '../../models/file-progress';
import { LocalService } from '../../local.service';
import { FileClass } from '../../models/fileClass';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { UserService } from '../../services/user.service';
import { NotifWaEmailSms } from '../../services/NotifWaEmailSms.service';

@Component({
  selector: 'app-minor-alteration',
  templateUrl: './minor-alteration.component.html',
  styleUrls: ['./minor-alteration.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [Config, AuditTrail, ClaimService, FileService, MinorAlterationService, UserService]
})
export class MinorAlterationComponent implements OnInit {
  fileClass: FileClass = new FileClass();
  private imageSrc: string = '';
  public data: any = {};
  public userId: any = '';
  errorMessage: string;
  params: any;
  loadingButton: boolean = false;

  public googleRecaptchaKey: any = '';
  public swalData: any = {};
  isChecked = false;

  responseHP: any;
  activationCountdown: any;
  activationTime = 5;

  otpPass: boolean = false;
  otpFail: boolean = false;
  process: boolean = false;
  otpNull: boolean = false;
  startOTP: number = 0;
  endOTP: number = 3;
  maxOtp: boolean = false;
  timeEnd: boolean = false;
  displayOTP: boolean = false;

  kodeOTP: any;
  capthaTemp: any;
  policyCode: any;
  perOTP: any;
  message = 'Kode OTP berhasil diverifikasi!';

  modalConfig: ModalOptions = { backdrop: 'static', keyboard: false, ignoreBackdropClick: true };
  @ViewChild('myOpen', { static: false }) myOpen: ElementRef<HTMLElement>;
  @ViewChild('closeDialog', { static: false }) closeDialog: ElementRef<HTMLElement>;

  minorAlterationRequest: MinorAlterationModel = new MinorAlterationModel();
  minorAlterationData: MinorAlterationModel = new MinorAlterationModel();
  @ViewChild('minorForm', { static: false }) minorForm: NgForm;

  policies: any[] = [];
  filteredNames: Observable<Insured[]>;
  dispatchTypes: Array<{ value: string; label: string }> = [
    // { value: 'LETTER', label: 'LETTER' },
    { value: 'EMAIL', label: 'EMAIL' }
  ];

  formStatus: {
    address: boolean;
    homeTel: boolean;
    mobile: boolean;
    officeTel: boolean;
    email: boolean;
    dispatchType: boolean;
  } = {
      address: false,
      homeTel: false,
      mobile: false,
      officeTel: false,
      email: false,
      dispatchType: false
    };

  disclaimer = new FormControl(false);

  rtrw = new FormGroup({
    rt: new FormControl(),
    rw: new FormControl()
  });

  formCountError: { count: number; form: string[] } = {
    count: 0,
    form: []
  };

  address = new FormGroup({
    streetName: new FormControl(),
    rtrw: this.rtrw,
    kelurahan: new FormControl(),
    kecamatan: new FormControl(),
    province: new FormControl(),
    postCode: new FormControl()
  });

  formGroup: FormGroup;
  successMessage: string;
  responseEmail: any;
  target: any;
  date: any;
  ext: any;
  dateFormat: any;
  newFileName: any;

  identityCardFileName: string = null;

  loadingMinor = true;

  identityCardFileProgress: FileProgress = { loading: false, progress: 0 };
  policyHolderDetail: any = {};

  constructor(
    private cnf: Config,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private router: Router,
    private claim: ClaimService,
    private formBuilder: FormBuilder,
    private file: FileService,
    private translate: TranslateService,
    private minorAlterationService: MinorAlterationService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private cpSweetalertService: CpSweetalertService,
    private localService: LocalService,
    private user: UserService,
    private notif: NotifWaEmailSms
  ) {
    // disable address form group at init form
    this.address.disable();
    this.rtrw.disable();

    // build form
    this.buildForm();
  }

  ngOnInit() {
    this.auditTrail.saveLog('Minor Alteration', 'Open');
    this.googleRecaptchaKey = this.cnf.googleRecaptchaKey;
    this.titleService.setTitle(this.cnf.prefixTitle + 'Minor Alteration' + this.cnf.postfixTitle);
    this.userId = this.localService.getJsonValue('userid');
    this.minorAlterationService.getMinorAlterationData(this.userId).subscribe(
      (res: any) => {
        this.loadingMinor = false;
        const response: RESTResponse<MinorAlterationModel> = res.body;
        this.policyHolderDetail = response;
        if (response.success) {
          this.minorAlterationData = { ...response.data };
          const dispatchType =
            this.minorAlterationData.dispatchType === 'PRINT'
              ? 'LETTER'
              : this.minorAlterationData.dispatchType === 'MAIL'
                ? 'EMAIL'
                : null;
          this.minorAlterationData.dispatchType = dispatchType;
          this.setValueForm(this.minorAlterationData);
        }
      },
      error => {
        this.loadingMinor = false;
        console.log('error', error);
      },
      () => {
        this.loadingMinor = false;
      }
    );
  }

  /**
   * @desc Set formGroup value
   * @param data MinorAlterationModel
   */
  setValueForm(data: MinorAlterationModel) {
    // Patch formData
    this.formGroup.patchValue({
      homeTel: data.homeTel,
      mobile: data.mobile,
      officeTel: data.officeTel,
      email: data.email,
      policyHolderName: data.policyHolderName,
      dispatchType: data.dispatchType
    });

    // add policyNumber to policies
    if (data.policyNumbers.length > 0) {
      for (const policyNumber of data.policyNumbers) {
        const newPolicy = {
          value: policyNumber.policyCode,
          label: policyNumber.policyCode
        };
        this.policies.push(newPolicy);
      }
    }
    // patch Address
    this.patchDefaultAddressValue(data);
  }

  /**
   * @desc Build formGroup
   */
  buildForm() {
    this.formGroup = this.formBuilder.group({
      policyNumber: new FormControl(null, Validators.required),
      policyHolderName: new FormControl({ value: null, disabled: true }, Validators.required),
      address: this.address,
      homeTel: new FormControl({ value: null, disabled: true }),
      mobile: new FormControl({ value: null, disabled: true }),
      officeTel: new FormControl({ value: null, disabled: true }),
      email: new FormControl({ value: null, disabled: true }),
      dispatchType: new FormControl({ value: null, disabled: true }),
      identityCardPhoto: new FormControl(null, [Validators.required]),
      disclaimer: this.disclaimer
    });
  }

  /**
   * @desc event when "Ubah" clicked in every form input
   * @param type {address, homeTel, mobile, officeTel, email, dispatchType}
   */
  onChangeClicked(type: string) {
    const formStatusClone = { ...this.formStatus };
    const numberPattern = Validators.pattern(/^[0-9]+$/);
    const validatorEmail = [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/)];

    if (type === 'address') {
      // Alamat
      const addressFormGroup = this.address as FormGroup;
      const rtrwFormGroup = this.rtrw as FormGroup;
      formStatusClone.address = !formStatusClone.address;

      if (formStatusClone.address) {
        addressFormGroup.enable();
        // set required validator
        this.validateAllFormGroupField(addressFormGroup, [Validators.required]);
        this.validateAllFormGroupField(rtrwFormGroup, [Validators.required, numberPattern]);
      } else {
        addressFormGroup.disable();
        // remove required validator
        this.validateAllFormGroupField(addressFormGroup, null);
        this.validateAllFormGroupField(rtrwFormGroup, null);
      }
      // reset address FormControl and add value to Address
      addressFormGroup.reset();
      this.rollbackFormData('address');
    } else if (type === 'homeTel') {
      // Nomor Telpon Rumah
      formStatusClone.homeTel = !formStatusClone.homeTel;
      const homeTelControl: FormControl = this.formGroup.controls.homeTel as FormControl;
      if (formStatusClone.homeTel) {
        homeTelControl.enable();
        homeTelControl.setValidators([Validators.required, numberPattern, Validators.maxLength(12)]);
      } else {
        homeTelControl.disable();
        homeTelControl.setValidators(null);
      }
      homeTelControl.reset();
      this.rollbackFormData('homeTel');
    } else if (type === 'mobile') {
      // Nomor Handphone
      formStatusClone.mobile = !formStatusClone.mobile;
      const mobileControl: FormControl = this.formGroup.controls.mobile as FormControl;
      if (formStatusClone.mobile) {
        mobileControl.enable();
        mobileControl.setValidators([Validators.required, numberPattern, Validators.maxLength(12)]);
      } else {
        mobileControl.disable();
        mobileControl.setValidators(null);
      }
      mobileControl.reset();
      this.rollbackFormData('mobile');
    } else if (type === 'officeTel') {
      // Nomor Telpon Kantor
      formStatusClone.officeTel = !formStatusClone.officeTel;
      const officeTelControl: FormControl = this.formGroup.controls.officeTel as FormControl;
      if (formStatusClone.officeTel) {
        officeTelControl.enable();
        officeTelControl.setValidators([Validators.required, numberPattern, Validators.maxLength(12)]);
      } else {
        officeTelControl.disable();
        officeTelControl.setValidators(null);
      }
      officeTelControl.reset();
      this.rollbackFormData('officeTel');
    } else if (type === 'email') {
      // Email
      formStatusClone.email = !formStatusClone.email;
      const emailTelControl: FormControl = this.formGroup.controls.email as FormControl;
      if (formStatusClone.email) {
        emailTelControl.enable();
        emailTelControl.setValidators(validatorEmail);
      } else {
        emailTelControl.disable();
        emailTelControl.setValidators(null);
      }
      emailTelControl.reset();
      this.rollbackFormData('email');
    } else if (type === 'dispatchType') {
      // Email
      formStatusClone.dispatchType = !formStatusClone.dispatchType;
      const dispatchTypeControl: FormControl = this.formGroup.controls.dispatchType as FormControl;
      if (formStatusClone.dispatchType) {
        dispatchTypeControl.enable();
        dispatchTypeControl.setValidators([Validators.required]);
      } else {
        dispatchTypeControl.disable();
        dispatchTypeControl.setValidators(null);
      }
      dispatchTypeControl.reset();
      this.rollbackFormData('dispatchType');
    }
    this.formStatus = formStatusClone;
  }

  /**
   * @desc for validate form
   * @param formGroup
   * @param validator
   */
  validateAllFormGroupField(formGroup: FormGroup, validator: ValidatorFn | ValidatorFn[] = null) {
    for (const field in formGroup.controls) {
      const control = formGroup.controls[field];
      if (control instanceof FormControl) {
        (formGroup.controls[field] as FormControl).setValidators(validator);
      }
    }
  }

  /**
   * @desc Roolback data
   * @param type {address, homeTel, mobile, officeTel, email, dispatchType}
   */
  rollbackFormData(type: string) {
    const data = this.minorAlterationData;
    if (type === 'address') {
      this.patchDefaultAddressValue(data);
    } else if (type === 'homeTel') {
      (this.formGroup.controls.homeTel as FormControl).setValue(data.homeTel);
    } else if (type === 'mobile') {
      (this.formGroup.controls.mobile as FormControl).setValue(data.mobile);
    } else if (type === 'officeTel') {
      (this.formGroup.controls.officeTel as FormControl).setValue(data.officeTel);
    } else if (type === 'email') {
      (this.formGroup.controls.email as FormControl).setValue(data.email);
    } else if (type === 'dispatchType') {
      (this.formGroup.controls.dispatchType as FormControl).setValue(data.dispatchType);
    }
  }

  /**
   * @desc to patch adreess input with data entered
   * @param data current minorAlteration data
   */
  patchDefaultAddressValue(data: MinorAlterationModel) {
    const rtrwData = this.splitRTRW(data.correspondenceAddress2);
    const rt = rtrwData[0] ? rtrwData[0] : null;
    const rw = rtrwData[1] ? rtrwData[1] : null;
    (this.formGroup.controls.address as FormGroup).patchValue({
      streetName: data.correspondenceAddress1,
      rtrw: {
        rt,
        rw
      },
      kelurahan: data.correspondenceAddress3,
      kecamatan: data.correspondenceAddress4,
      province: data.correspondenceAddress5,
      postCode: data.correspondencePostCode
    });
  }

  /**
   * @desc Split RT RW in address
   * @param rtrw
   */
  splitRTRW(rtrw: string) {
    const regexRTRW = new RegExp('(RT.\\s)|(RW.\\s)', 'g');
    rtrw = rtrw.replace(regexRTRW, '').trim();
    return rtrw.split(' ');
  }

  /**
   * @desc on Policy Change
   */
  policyChange(event: any) {
    this.claim.getInsuredCustomers(event.value).subscribe((resp: any) => {
      const responses: RESTResponse<Insured[]> = resp.body;
      if (responses.success) {
      }
    });
  }

  /**
   * @desc on disclaimer checbox change
   */
  changeCheck(event: any) {
    // reset form Error Counter
    this.formCountError.count = 0;
    this.formCountError.form.length = 0;

    this.errorAllCounter(this.formGroup);

    const count = this.formCountError.count;

    if (count === 0) {
      this.isChecked = true;
      this.disclaimer.setValue(true);
    } else {
      this.isChecked = false;
      this.disclaimer.setValue(false);
      this.validateAllFormFields(this.formGroup);
      if (this.formCountError.count === 1 && this.formCountError.form[0] === 'identityCardPhoto') {
        this.cpSweetalertService.show('_minor_identity_card_scan_warning', 'warning');
      } else {
        this.cpSweetalertService.show('_eclaim_not_complete_warning', 'warning');
      }
    }
  }

  /**
   * @desc Check all error form input
   */
  errorAllCounter(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormControl) {
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(_ => {
            this.formCountError.count++;
            this.formCountError.form.push(key);
          });
        }
      } else if (control instanceof FormGroup) {
        this.errorAllCounter(control);
      }
    });
    return this.formCountError;
  }

  /**
   * @desc create custom random char for file-name
   */
  makecustom(length: any) {
    var a = new Date();

    var uuid = require("uuid");
    var id = btoa(a + '').slice(-7, -2) + '' + btoa(uuid.v4()).slice(-7, -2);

    return id;
  }

  /**
   * @desc on KTP selected function
   */
  onIdentityCardFileSelect(event: any) {
    var file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file);
    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }


  _handleReaderLoaded(e: any, event: any) {
    let reader2 = event.target;
    this.imageSrc = reader2.result;
    this.fileClass.fileDownloadUri = this.imageSrc;
    this.fileClass.fileName = this.newFileName;
    this.fileClass.fileType = this.ext;
    this.fileClass.size = this.target.size;
    this.fileClass.userId = this.userId;
    this.file.uploadWithNProgress(this.fileClass).subscribe(
      (res: any) => {
        const response = res.body;
        if (response.success) {
          const identityCardPhoto = response.data.fileDownloadUri;
          this.minorAlterationRequest.identityCardPhoto = identityCardPhoto;

          this.identityCardFileName = this.newFileName;

          this.cpSweetalertService.show('Upload File Berhasil.', 'success', false);
        }
      }
      , err => {
        console.log(err);
        this.formGroup.get('identityCardPhoto').setValue(null);
        this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
      },
      () => {
        this.identityCardFileProgress = { loading: false, progress: 0 };
        this.formGroup.get('identityCardPhoto').enable();
      }
    );
  }

  /**
   * @desc on submit minor-alteration
   */
  onSubmit() {
    if (this.isChecked) {
      this.cnf.showRecaptchaBadge();
      this.loadingButton = true;
      this.reCaptchaV3Service.execute(this.cnf.googleRecaptchaKeyv3, 'minoralteration', captchaToken => {
        const formValue = this.formGroup.value;
        const formAddressValue = this.address.value;
        const rtrwValue = formAddressValue.rtrw;
        if (this.formStatus.address) {
          this.minorAlterationRequest.correspondenceAddress1 = formAddressValue.streetName;
          this.minorAlterationRequest.correspondenceAddress2 = `RT. ${rtrwValue.rt} RW. ${rtrwValue.rw}`;
          this.minorAlterationRequest.correspondenceAddress3 = formAddressValue.kelurahan;
          this.minorAlterationRequest.correspondenceAddress4 = formAddressValue.kecamatan;
          this.minorAlterationRequest.correspondenceAddress5 = formAddressValue.province;
          this.minorAlterationRequest.correspondencePostCode = formAddressValue.postCode;

          //set audit trail data customer before after
          if (this.minorAlterationData.correspondenceAddress1 != formAddressValue.streetName) {
            this.auditTrail.auditTrailBefore.correspondenceAddress1 = this.minorAlterationData.correspondenceAddress1 != null ? this.minorAlterationData.correspondenceAddress1 : "";
            this.auditTrail.auditTrailAfter.correspondenceAddress1 = formAddressValue.streetName;
          }
          if (this.minorAlterationData.correspondenceAddress2 != `RT. ${rtrwValue.rt} RW. ${rtrwValue.rw}`) {
            this.auditTrail.auditTrailBefore.correspondenceAddress2 = this.minorAlterationData.correspondenceAddress2 != null ? this.minorAlterationData.correspondenceAddress2 : "";
            this.auditTrail.auditTrailAfter.correspondenceAddress2 = rtrwValue != null ? `RT. ${rtrwValue.rt} RW. ${rtrwValue.rw}` : "";
          }
          if (this.minorAlterationData.correspondenceAddress3 != formAddressValue.kelurahan) {
            this.auditTrail.auditTrailBefore.correspondenceAddress3 = this.minorAlterationData.correspondenceAddress3 != null ? this.minorAlterationData.correspondenceAddress3 : "";
            this.auditTrail.auditTrailAfter.correspondenceAddress3 = formAddressValue.kelurahan;
          }
          if (this.minorAlterationData.correspondenceAddress4 != formAddressValue.kecamatan) {
            this.auditTrail.auditTrailBefore.correspondenceAddress4 = this.minorAlterationData.correspondenceAddress4 != null ? this.minorAlterationData.correspondenceAddress4 : "";
            this.auditTrail.auditTrailAfter.correspondenceAddress4 = formAddressValue.kecamatan;
          }
          if (this.minorAlterationData.correspondenceAddress5 != formAddressValue.province) {
            this.auditTrail.auditTrailBefore.correspondenceAddress5 = this.minorAlterationData.correspondenceAddress5 != null ? this.minorAlterationData.correspondenceAddress5 : "";
            this.auditTrail.auditTrailAfter.correspondenceAddress5 = formAddressValue.province;
          }
          if (this.minorAlterationData.correspondencePostCode != formAddressValue.postCode) {
            this.auditTrail.auditTrailBefore.postalCode = this.minorAlterationData.correspondencePostCode != null ? this.minorAlterationData.correspondencePostCode : "";
            this.auditTrail.auditTrailAfter.postalCode = formAddressValue.postCode;
          }
        }

        if (this.formStatus.homeTel) {
          this.minorAlterationRequest.homeTel = formValue.homeTel;
          if (this.minorAlterationData.homeTel != formValue.homeTel) {
            this.auditTrail.auditTrailBefore.homePhonenumber = this.minorAlterationData.homeTel != null ? this.minorAlterationData.homeTel : "";
            this.auditTrail.auditTrailAfter.homePhonenumber = formValue.homeTel;
          }
        }

        if (this.formStatus.email) {
          this.minorAlterationRequest.email = formValue.email;
          if (this.minorAlterationData.email != formValue.email) {
            this.auditTrail.auditTrailBefore.email = this.minorAlterationData.email != null ? this.minorAlterationData.email : "";
            this.auditTrail.auditTrailAfter.email = formValue.email;
          }
        }
        if (this.formStatus.mobile) {
          this.minorAlterationRequest.mobile = formValue.mobile;
          if (this.minorAlterationData.mobile != formValue.mobile) {
            this.auditTrail.auditTrailBefore.mobilePhonenumber = this.minorAlterationData.mobile != null ? this.minorAlterationData.mobile : "";
            this.auditTrail.auditTrailAfter.mobilePhonenumber = formValue.mobile;
          }
        }
        if (this.formStatus.officeTel) {
          this.minorAlterationRequest.officeTel = formValue.officeTel;
          if (this.minorAlterationData.officeTel != formValue.officeTel) {
            this.auditTrail.auditTrailBefore.officePhonenumber = this.minorAlterationData.officeTel != null ? this.minorAlterationData.officeTel : "";
            this.auditTrail.auditTrailAfter.officePhonenumber = formValue.officeTel;
          }
        }
        if (this.formStatus.dispatchType) {
          this.minorAlterationRequest.dispatchType = formValue.dispatchType;
          if (this.minorAlterationData.dispatchType != formValue.dispatchType) {
            this.auditTrail.auditTrailBefore.dispatchType = this.minorAlterationData.dispatchType != null ? this.minorAlterationData.dispatchType : "";
            this.auditTrail.auditTrailAfter.dispatchType = formValue.dispatchType;
          }
        }
        this.minorAlterationRequest.policyNumber = formValue.policyNumber;
        this.minorAlterationRequest.policyHolderName = this.minorAlterationData.policyHolderName;
        this.capthaTemp = captchaToken;
        this.policyCode = formValue.policyNumber;

        this.user.requestOTP(this.userId, 'minor_alteration', 'sms').subscribe((res: any) => {
          this.process = false;
          this.kodeOTP = null;
          this.startOTP = (+res.body.datas.countotp) + 1;
          if (this.startOTP <= 3) {
            this.otpFail = false;
            if (res.body.datas.handphone != null) {
              this.activationCountdown = new Date().setMinutes(new Date().getMinutes() + this.activationTime);
              this.responseHP = res.body.datas.handphone;
              this.perOTP = this.startOTP + '/' + this.endOTP;
              if (this.startOTP == this.endOTP) {
                this.maxOtp = true;
              }
              let el: HTMLElement = this.myOpen.nativeElement;
              el.click();
            }
          } else {
            this.message = res.body.datas.msg;
            this.displayOTP = true;
            this.otpFail = true;
            let el: HTMLElement = this.myOpen.nativeElement;
            el.click();
          }
        }, err => {
          console.log(err.message);
        });

      });
    } else {
      this.cpSweetalertService.show('_eclaim_disclaimer_warning', 'warning');
    }
  }

  /**
   * @desc fire validate form
   * @param formGroup
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * @desc get Option
   */
  getOptions(option) {
    if (option != null) {
      return option.fullName;
    }
    return '';
  }

  /**
   * @desc Reset Form
   */
  resetForm() {
    this.minorForm.resetForm();
    this.policies.length = 0;
    this.setValueForm(this.minorAlterationData);
    this.formStatus = {
      address: false,
      homeTel: false,
      mobile: false,
      officeTel: false,
      email: false,
      dispatchType: false
    };
    this.address.disable();
    this.rtrw.disable();
    this.formGroup.controls['homeTel'].disable();
    this.formGroup.controls['mobile'].disable();
    this.formGroup.controls['officeTel'].disable();
    this.formGroup.controls['email'].disable();
    this.formGroup.controls['dispatchType'].disable();
    this.minorAlterationRequest = new MinorAlterationModel();
  }
  doGoHome() {
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    this.cnf.removeRecaptchaBadge();
  }

  activationTimeout() {
    this.timeEnd = true;
  }

  sendAgin() {
    this.kodeOTP = null;
    this.process = true;
    this.user.requestOTP(this.userId, 'minor_alteration', 'sms').subscribe((res: any) => {
      this.startOTP = (+res.body.datas.countotp) + 1;
      if (this.startOTP <= 3) {
        this.timeEnd = false;
        this.process = false;
        this.otpPass = true;
        this.message = 'Kode OTP berhasil dikirim ulang!';
        this.responseHP = res.body.datas.handphone;
        this.perOTP = this.startOTP + '/' + this.endOTP;
        if (this.startOTP == this.endOTP) {
          this.maxOtp = true;
        }
        this.activationCountdown = new Date().setMinutes(new Date().getMinutes() + 3);
        setTimeout(() => {
          this.otpPass = false;
        }, 500);
      } else {
        this.message = res.body.datas.msg;
        this.displayOTP = true;
        this.otpFail = true;
        let el: HTMLElement = this.myOpen.nativeElement;
        el.click();
      }
    }, (err: any) => {
      console.log(err.message);
    });
  }

  submitOTP() {
    if (this.kodeOTP != null) {
      this.user.checkOTP(this.userId, 'minor_alteration', this.kodeOTP).subscribe((res: any) => {
        if (res.body.datas.corret == "Y") {
          this.message = 'Kode OTP berhasil diverifikasi!';
          this.otpPass = true;
          this.process = true;
          setTimeout(() => {
            let el: HTMLElement = this.closeDialog.nativeElement;
            el.click();
            const req = new RESTRequest<MinorAlterationModel>();
            req.appid = this.cnf.appid;
            req.appkey = this.cnf.appkey;
            req.data = this.minorAlterationRequest;
            req.apptype = 'web';
            req.token = this.capthaTemp;
            this.minorAlterationService
              .submitMinor(req, this.userId)
              .subscribe(
                (res: any) => {
                  const response = res.body;
                  if (response.success) {
                    this.cpSweetalertService.show('_minor_submitted_success', 'success');
                    this.auditTrail.saveLog('Minor Alteration', 'Submit Success#' + this.policyCode);
                    this.notif.sendNotification(this.policyHolderDetail.email, this.policyHolderDetail.mobile, this.policyCode, "Minor Alteration");
                    this.resetForm();
                  }
                }, (err: any) => {
                  if (err.status === 400) {
                    const msg = err.error;
                    const message = this.translate.instant(msg.message);
                    this.auditTrail.saveLog('Minor Alteration', 'Submit Failed#' + this.policyCode + " #" + msg.message);
                    this.cpSweetalertService.show(message, 'error');
                  } else {
                    const msg = err.error;
                    const message = this.translate.instant(msg.message);
                    this.auditTrail.saveLog('Minor Alteration', 'Submit Failed#' + this.policyCode + " #" + msg.message);
                    this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
                    this.cpSweetalertService.show(message, 'error');
                  }
                }
              )
              .add(() => {
                this.loadingButton = false;
                this.process = false;
              });
            this.otpPass = false;
          }, 500);
        } else {
          this.otpFail = true;
          this.message = 'Kode OTP yang anda masukkan tidak valid';
          setTimeout(() => {
            this.otpFail = false;
          }, 500);
        }
      }, (err: any) => {
        console.log(err.message);
      });
    } else {
      this.otpNull = true;
    }
  }

  closeOTP() {
    this.loadingButton = false;
    let el: HTMLElement = this.closeDialog.nativeElement;
    el.click();
  }

}
