import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, RequiredValidator, ValidationErrors, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { CpSweetalertService } from '../../services/cp-sweetalert.service';
import { AuditTrail } from '../../audit-trail';
import { Config } from '../../config';
import { CustomerService } from '../../services/customer.service';
import { ActivatedRoute } from '@angular/router';
import { MinorAlterationService } from '../../services/minor-alteration.service';
import { RESTResponse } from '../../models/restresponse';
import { ReqModel } from '../../models/reqModel';
import { LocalService } from '../../local.service';
import { FileClass } from '../../models/fileClass';
import { FileService } from '../../services/file.service';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { UserService } from '../../services/user.service';
import { RESTRequest } from '../../models/restrequest.model';
import { ChangeRequest } from '../../models/ChangeRequest';
import { NotifWaEmailSms } from '../../services/NotifWaEmailSms.service';

@Component({
  selector: 'app-change-frequency',
  templateUrl: './change-frequency.component.html',
  styleUrls: ['./change-frequency.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [Config, AuditTrail, MinorAlterationService, FileService, UserService]
})
export class ChangeFrequencyComponent implements OnInit {

  @ViewChild('ngForm', { static: false }) ngForm: NgForm;
  formGroup: FormGroup;
  loading: boolean = false;
  loadingPolicy: boolean = false;
  loadingButton: boolean = false;
  policies: any = [];
  freqPaymentList: any = [];
  googleRecaptchaKey: any = '';
  userId: any = '';
  params: any;
  policyDetailData: any = {};
  reqData: ReqModel = new ReqModel();
  identityCardFileName: string = null;

  fileClass: FileClass = new FileClass();
  private imageSrc: string = '';
  target: any;
  date: any;
  ext: any;
  dateFormat: any;
  newFileName: any;

  disclaimer = new FormControl(false);
  isChecked = false;
  monthBefore: number = 0;

  formCountError: { count: number; form: string[] } = {
    count: 0,
    form: []
  };

  note: any;

  otpPass: boolean = false;
  otpFail: boolean = false;
  process: boolean = false;
  otpNull: boolean = false;
  startOTP: number = 0;
  endOTP: number = 3;
  maxOtp: boolean = false;
  timeEnd: boolean = false;
  displayOTP: boolean = false;

  kodeOTP: any;
  capthaTemp: any;
  policyCode: any;
  perOTP: any;
  message = 'Kode OTP berhasil diverifikasi!';
  responseHP: any;
  activationCountdown: any;
  activationTime = 5;

  modalConfig: ModalOptions = { backdrop: 'static', keyboard: false, ignoreBackdropClick: true };
  @ViewChild('myOpen', { static: false }) myOpen: ElementRef<HTMLElement>;
  @ViewChild('closeDialog', { static: false }) closeDialog: ElementRef<HTMLElement>;
  @ViewChild('myOTP', { static: false }) myOTP: ElementRef<HTMLElement>;

  reqRequest: ChangeRequest = new ChangeRequest();

  freqPaymentHowList: any = [];

  freqHowA: any = [
    { 'id': '1', 'name': 'Tunai: Virtual Account' },
    { 'id': '2', 'name': 'Debit Kartu Kredit sekali' },
    { 'id': '3', 'name': 'Auto Debit Kartu Kredit' }
  ];

  freqHowB: any = [
    { 'id': '1', 'name': 'Tunai: Virtual Account' },
    { 'id': '2', 'name': 'Debit Kartu Kredit sekali' },
    { 'id': '3', 'name': 'Auto Debit Kartu Kredit' },
    { 'id': '4', 'name': 'Auto Debit Rekening Bank' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private minorAlterationService: MinorAlterationService,
    private translate: TranslateService,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private cpSweetalertService: CpSweetalertService,
    private customerService: CustomerService,
    private localService: LocalService,
    private route: ActivatedRoute,
    private cnf: Config,
    private file: FileService,
    private user: UserService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private notif: NotifWaEmailSms

  ) {
    this.route.params.subscribe(params => (this.params = params));
    this.buildForm()
  }

  ngOnInit() {
    this.auditTrail.saveLog('Change Frequency Payment', 'Open');
    this.loadingPolicy = true;
    this.googleRecaptchaKey = this.cnf.googleRecaptchaKey;
    this.titleService.setTitle(this.cnf.prefixTitle + 'Change Frequency Payment' + this.cnf.postfixTitle);
    this.userId = this.localService.getJsonValue('userid');
    this.minorAlterationService.getMinorAlterationData(this.userId).subscribe(
      (res: any) => {
        const response: RESTResponse<ReqModel> = res.body;
        if (response.success) {
          this.reqData = { ...response.data };
          if (this.reqData.policyNumbers.length > 0) {
            for (const policyNumber of this.reqData.policyNumbers) {
              const newPolicy = {
                value: policyNumber.policyCode,
                label: policyNumber.policyCode
              };
              this.policies.push(newPolicy);
            }
            this.formGroup.controls.policyNo.setValue(this.params.policyNo);
            this.detailPersonal(this.params.policyNo);
            this.loadingPolicy = false;
          }
        }
      }
    );
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      policyNo: new FormControl(null, Validators.required),
      policyHolderName: new FormControl({ value: null, disabled: true }, Validators.required),
      mainLifeAssured: new FormControl({ value: null, disabled: true }, Validators.required),
      freqPaymentBefore: new FormControl({ value: null, disabled: true }, Validators.required),
      freqPaymentAfter: new FormControl(null, Validators.required),
      identityCardPhoto: new FormControl(null, Validators.required),
      freqPaymentHow: new FormControl(null, Validators.required),
      disclaimer: new FormControl(null, Validators.required)
    });
  }

  onSubmit() {
    if (this.isChecked) {
      this.cnf.showRecaptchaBadge();
      this.loadingButton = true;
      this.reCaptchaV3Service.execute(this.cnf.googleRecaptchaKeyv3, 'reqchangepaymentfreq', captchaToken => {
        const formValue = this.formGroup.value;
        this.policyCode = formValue.policyNo;
        this.capthaTemp = captchaToken;

        this.reqRequest.policyCode = this.policyCode;
        this.reqRequest.typeRequest = "freq_payment";
        this.reqRequest.subject = "New Request Change Frequency Payment Premi - " + this.policyCode + " - " + this.policyDetailData.policyHolderName;
        this.reqRequest.title = "You Have Received New Request Change Frequency Payment from Customer Corner"
        this.reqRequest.policyHolderBefore = this.policyDetailData.policyHolderName;
        this.reqRequest.mainLifeAssured = this.policyDetailData.mainLifeAssured;
        this.reqRequest.paymentMode = formValue.freqPaymentHow;
        this.reqRequest.freqBefore = this.getTransalte(this.policyDetailData.paymentFrequency);
        this.reqRequest.freqAfter = this.getChargeName(formValue.freqPaymentAfter);
        this.reqRequest.note = this.note;

        if (this.reqRequest.freqAfter != null) {
          this.auditTrail.auditTrailBefore.freqPayment = this.getTransalte(this.policyDetailData.paymentFrequency);
          this.auditTrail.auditTrailAfter.freqPayment = this.reqRequest.freqAfter;
        }

        this.user.requestOTP(this.userId, 'change_freq', 'email').subscribe((res: any) => {
          this.startOTP = (+res.body.datas.countotp) + 1;
          this.process = false;
          this.kodeOTP = null;
          if (this.startOTP <= 3) {
            if (res.body.datas.handphone != null || res.body.datas.email != null) {
              this.activationCountdown = new Date().setMinutes(new Date().getMinutes() + this.activationTime);
              this.responseHP = res.body.datas.handphone;
              this.perOTP = this.startOTP + '/' + this.endOTP;
              if (this.startOTP == this.endOTP) {
                this.maxOtp = true;
              }
              let el: HTMLElement = this.myOpen.nativeElement;
              el.click();
              let ell: HTMLElement = this.myOTP.nativeElement;
              ell.onfocus;
            }
          } else {
            this.message = res.body.datas.msg;
            this.displayOTP = true;
            this.otpFail = true;
            let el: HTMLElement = this.myOpen.nativeElement;
            el.click();
          }
        }, err => {
          console.log(err.message);
        });
      });
    } else {
      this.cpSweetalertService.show('_eclaim_disclaimer_warning', 'warning');
    }
  }

  choicePolicy(data: any) {
    this.detailPersonal(data.value);
  }

  detailPersonal(policyNo: any) {
    this.customerService.getPolicyInformation(policyNo, this.cnf.token).subscribe((res: any) => {
      if (res.body.status == 100) {
        let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
        this.policyDetailData = decryptData[0].policyInformation_detail[0];
        this.formGroup.controls.policyHolderName.setValue(this.policyDetailData.policyHolderName);
        this.formGroup.controls.mainLifeAssured.setValue(this.policyDetailData.mainLifeAssured);
        this.translate.get(this.policyDetailData.paymentFrequency).subscribe((res: any) => {
          this.formGroup.controls.freqPaymentBefore.setValue(res);
        });
        this.getChargeMode(this.policyDetailData.paymentFrequency);
      }
    });
  }

  getChargeMode(freq: any) {
    this.customerService.getChargeMode().subscribe((res: any) => {
      if (res.status == 200) {
        for (var obj of res.body.data) {
          if (obj.chargeName != freq) {
            this.freqPaymentList.push(obj);
          }
          if (obj.chargeName == freq) {
            this.monthBefore = obj.months;
          }
        }
      }
    });
  }

  choiceChargeMode(data: any) {
    if (data.value != undefined) {
      if (this.monthBefore > data.value) {
        this.note = '*Tidak boleh ada dana sebesar apapun di GS dan Temporary Suspen untuk JT berikutnya';
      } else {
        for (var obj of this.freqPaymentList) {
          if (obj.months == data.value) {
            this.translate.get(obj.chargeName).subscribe((res: any) => {
              this.note = '*Siklus Frekuensi ' + res + ' nya sudah dibayarkan dahulu';
            });
          }
        }
      }

      if (this.monthBefore == 1 && data.value == 3 || data.value == 6 || data.value == 12) {
        this.freqPaymentHowList = this.freqHowB;
      } else if (this.monthBefore == 3 && data.value == 1) {
        this.freqPaymentHowList = this.freqHowA;
      } else if (this.monthBefore == 3 && data.value == 6 || data.value == 12) {
        this.freqPaymentHowList = this.freqHowB;
      } else if (this.monthBefore == 6 && data.value == 1) {
        this.freqPaymentHowList = this.freqHowA;
      } else if (this.monthBefore == 6 && data.value == 3 || data.value == 12) {
        this.freqPaymentHowList = this.freqHowB;
      } else if (this.monthBefore == 12 && data.value == 1) {
        this.freqPaymentHowList = this.freqHowA;
      } else if (this.monthBefore == 12 && data.value == 3 || data.value == 6) {
        this.freqPaymentHowList = this.freqHowB;
      }
    } else {
      this.note = '';
      this.freqPaymentHowList = [];
    }
  }

  getChargeName(month: any) {
    var result = "";
    for (var obj of this.freqPaymentList) {
      if (obj.months == month) {
        this.translate.get(obj.chargeName).subscribe((res: any) => {
          result = res;
        });
      }
    }
    return result;
  }

  onIdentityCardFileSelect(event: any) {
    var file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();
    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file);
    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  _handleReaderLoaded(e: any, event: any) {
    let reader2 = event.target;
    this.imageSrc = reader2.result;
    this.fileClass.fileDownloadUri = this.imageSrc;
    this.fileClass.fileName = this.newFileName;
    this.fileClass.fileType = this.ext;
    this.fileClass.size = this.target.size;
    this.fileClass.userId = this.userId;
    this.file.uploadWithNProgress(this.fileClass).subscribe(
      (res: any) => {
        const response = res.body;
        if (response.success) {
          const identityCardPhoto = response.data.fileDownloadUri;
          this.reqRequest.idCard = identityCardPhoto;
          this.identityCardFileName = this.newFileName;

          this.cpSweetalertService.show('Unggah Dokumen Berhasil', 'success', false);
        }
      }
      , (err: any) => {
        console.log(err);
        this.formGroup.get('identityCardPhoto').setValue(null);
        this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
      },
      () => {
        this.formGroup.get('identityCardPhoto').enable();
      }
    );
  }

  makecustom(length: any) {
    var a = new Date();
    var uuid = require("uuid");
    var id = btoa(a + '').slice(-7, -2) + '' + btoa(uuid.v4()).slice(-7, -2);
    return id;
  }

  resetForm() {
    this.ngForm.reset();
    this.note = '';
  }

  changeCheck(event: any) {
    this.formCountError.count = 0;
    this.formCountError.form.length = 0;
    this.errorAllCounter(this.formGroup);
    const count = this.formCountError.count;
    if (count === 0) {
      this.isChecked = true;
      this.disclaimer.setValue(true);
    } else {
      this.isChecked = false;
      this.disclaimer.setValue(false);
      this.validateAllFormFields(this.formGroup);
      if (this.formCountError.count === 1 && this.formCountError.form[0] === 'identityCardPhoto') {
        this.cpSweetalertService.show('_minor_identity_card_scan_warning', 'warning');
      } else {
        this.cpSweetalertService.show('Permintaan tidak dapat diproses, mohon lengkapi formulir dan mengunggah dokumen', 'warning');
      }
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  errorAllCounter(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormControl) {
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(_ => {
            this.formCountError.count++;
            this.formCountError.form.push(key);
          });
        }
      } else if (control instanceof FormGroup) {
        this.errorAllCounter(control);
      }
    });
    return this.formCountError;
  }

  activationTimeout() {
    this.timeEnd = true;
  }

  sendAgin() {
    this.kodeOTP = null;
    this.process = true;
    this.user.requestOTP(this.userId, 'change_freq', 'sms').subscribe((res: any) => {
      this.startOTP = (+res.body.datas.countotp) + 1;
      if (this.startOTP <= 3) {
        this.timeEnd = false;
        this.process = false;
        this.otpPass = true;
        this.message = 'Kode OTP berhasil dikirim ulang!';
        this.responseHP = res.body.datas.handphone;
        this.perOTP = this.startOTP + '/' + this.endOTP;
        if (this.startOTP == this.endOTP) {
          this.maxOtp = true;
        }
        this.activationCountdown = new Date().setMinutes(new Date().getMinutes() + 3);
        setTimeout(() => {
          this.otpPass = false;
        }, 200);
        let el: HTMLElement = this.myOTP.nativeElement;
        el.onfocus;
      } else {
        this.message = res.body.datas.msg;
        this.displayOTP = true;
        this.otpFail = true;
        let el: HTMLElement = this.myOpen.nativeElement;
        el.click();
      }
    }, (err: any) => {
      console.log(err.message);
    });
  }

  submitOTP() {
    if (this.kodeOTP != null) {
      this.user.checkOTP(this.userId, 'change_freq', this.kodeOTP).subscribe((res: any) => {
        if (res.body.datas.corret == "Y") {
          this.message = 'Kode OTP berhasil diverifikasi!';
          this.otpPass = true;
          this.process = true;
          setTimeout(() => {
            let el: HTMLElement = this.closeDialog.nativeElement;
            el.click();
            const req = new RESTRequest<ChangeRequest>();
            req.appid = this.cnf.appid;
            req.appkey = this.cnf.appkey;
            req.data = this.reqRequest;
            req.apptype = 'web';
            req.token = this.capthaTemp;
            this.customerService
              .changeRequest(req)
              .subscribe(
                (res: any) => {
                  const response = res.body;
                  if (response.success) {
                    this.cpSweetalertService.show('Terima kasih, permintaan anda sudah kami terima.', 'success');
                    this.auditTrail.saveLog('Change Frequency Payment', 'Submit Success#' + this.policyCode);
                    this.notif.sendNotification(this.policyDetailData.email, this.policyDetailData.mobile, this.policyCode, "Perubahan Frekuensi Pembayaran");
                    this.resetForm();
                  }
                },
                err => {
                  if (err.status === 400) {
                    const msg = err.error;
                    const message = this.translate.instant(msg.message);
                    this.cpSweetalertService.show(message, 'error');
                    this.auditTrail.saveLog('Change Frequency Payment', 'Submit Failed#' + this.policyCode + ' #' + msg.message);
                  } else {
                    console.error(err);
                    const msg = err.error;
                    const message = this.translate.instant(msg.message);
                    this.cpSweetalertService.show(message, 'error');
                    this.auditTrail.saveLog('Change Frequency Payment', 'Submit Failed#' + this.policyCode + ' #' + msg.message);
                  }
                }
              )
              .add(() => {
                this.loadingButton = false;
                this.process = false;
              });
            this.otpPass = false;
          }, 500);
        } else {
          this.otpFail = true;
          this.message = 'Kode OTP yang anda masukkan tidak valid';
        }
      }, (err: any) => {
        console.log(err.message);
      });
    } else {
      this.otpNull = true;
    }
  }

  closeOTP() {
    this.loadingButton = false;
    let el: HTMLElement = this.closeDialog.nativeElement;
    el.click();
  }

  getTransalte(key: any) {
    var result = "";
    this.translate.get(this.policyDetailData.paymentFrequency).subscribe((res: any) => {
      result = res;
    });
    return result;
  }

}
