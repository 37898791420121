import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Config } from '../config';
import { APIResponse } from '../models/apiresponse';
import { ReplaySubject } from 'rxjs';
import { APIService } from './api.service';
import { LocalService } from '../local.service';

@Injectable()
export class UserService {

  constructor(private cnf: Config, private api: APIService, private http: HttpClient, private localService: LocalService) { }

  getAttoken(params: HttpParams) {
    return this.api.post(this.cnf.URLWSNonPublicArea + '/user_frontend/getATToken', this.cnf.convertPostData(params));
  }

  login(params: HttpParams, data: {}) {
    return this.api.post(this.cnf.URLWSNonPublicArea + '/user_frontend/login', this.cnf.convertPostData(params, data));
  }

  updatePassBcrypt(params: HttpParams, data: {}) {
    return this.api.post(this.cnf.URLCustomerService + '/updateBcrypt', this.cnf.convertPostData(params, data));
  }

  logout(params: HttpParams) {
    return this.api.post(this.cnf.URLWSNonPublicArea + '/user_frontend/logout', this.cnf.convertPostData(params));
  }
  activation(params: HttpParams) {
    return this.api.post(this.cnf.URLWSNonPublicArea + '/user_frontend/activation', this.cnf.convertPostData(params));
  }
  checkActivation(params: HttpParams) {
    return this.api.post(
      this.cnf.URLWSNonPublicArea + '/user_frontend/checkActivation',
      this.cnf.convertPostData(params)
    );
  }
  updatePassword(params: HttpParams, data: {}) {
    return this.api.post(
      this.cnf.URLWSNonPublicArea + '/user_frontend/updatePass',
      this.cnf.convertPostData(params, data)
    );
  }
  checkToken(params: HttpParams) {
    return this.api.post(this.cnf.URLWSNonPublicArea + '/user_frontend/checkToken', this.cnf.convertPostData(params));
  }
  loadSession(params: HttpParams) {
    return this.api.post(
      this.cnf.URLWSNonPublicArea + '/user_frontend/getfrontendtime',
      this.cnf.convertPostData(params)
    );
  }
  personalData(params: HttpParams, userId: any) {
    return this.api.post(
      this.cnf.URLWSNonPublicArea + '/personal_data/data/userid/' + userId,
      this.cnf.convertPostData(params)
    );
  }

  requestOTP(userId: any, purpose: any, gateway: any) {
    // const headersHead = new HttpHeaders({
    //   'Content-Type': 'application/json; charset=utf-8',
    //   'Cache-Control': 'no-cache,no-store,max-age=0,must-revalidate',
    //   'Access-Control-Allow-Origin': 'https://customercorner.chubblife.co.id',
    //   'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    //   'Access-Control-Allow-Credentials': 'true',
    //   Pragma: 'no-cache',
    //   Expires: '-1',
    //   'X-XSS-Protection': '1;mode=block',
    //   'X-Frame-Options': 'SAMEORIGIN',
    //   'X-Content-Type-Options': 'nosniff',
    //   'X-Auth-Token': (btoa(this.localService.getJsonValue('token') + '#' + this.localService.getJsonValue('userid')))
    // });
    // return this.http.get(this.cnf.URLWSNonPublicArea + '/user_frontend/reqOTP/' + userId + '/' + purpose + '/' + gateway, {headers: headersHead});
    return this.api.get(this.cnf.URLWSNonPublicArea + '/user_frontend/reqOTP/' + userId + '/' + purpose + '/' + gateway)
    // return this.http.get();
  }

  checkOTP(userId: any, purpose: any, otp: any) {
    // const headersHead = new HttpHeaders({
    //   'Content-Type': 'application/json; charset=utf-8',
    //   'Cache-Control': 'no-cache,no-store,max-age=0,must-revalidate',
    //   'Access-Control-Allow-Origin': 'https://customercorner.chubblife.co.id',
    //   'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    //   'Access-Control-Allow-Credentials': 'true',
    //   Pragma: 'no-cache',
    //   Expires: '-1',
    //   'X-XSS-Protection': '1;mode=block',
    //   'X-Frame-Options': 'SAMEORIGIN',
    //   'X-Content-Type-Options': 'nosniff',
    //   'X-Auth-Token': (btoa(this.localService.getJsonValue('token') + '#' + this.localService.getJsonValue('userid')))
    // });
    return this.api.get(this.cnf.URLWSNonPublicArea + '/user_frontend/checkOTP/' + userId + '/' + purpose + '/' + otp);
    // return this.http.get(this.cnf.URLWSNonPublicArea + '/user_frontend/checkOTP/' + userId + '/' + purpose + '/' + otp, {headers: headersHead});
  }
}
